import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

// import { ApplicationConfigService } from 'app/core/config/application-config.service';
// import { createRequestOption } from 'app/core/request/request-util';
// import { Pagination } from 'app/core/request/request.model';
import { IUser } from 'app/modules/shared/models/user-management.model';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UserManagementService {
    protected repoURL = environment.repoUrl;
    protected resourceUrl = this.repoURL + '/api/admin/users';

    constructor(private http: HttpClient) {}

    create(user: IUser): Observable<IUser> {
        return this.http.post<IUser>(this.resourceUrl, user);
    }

    update(user: IUser): Observable<IUser> {
        return this.http.put<IUser>(this.resourceUrl, user);
    }

    find(login: string): Observable<IUser> {
        return this.http.get<IUser>(`${this.resourceUrl}/${login}`);
    }

    //   query(req?: Pagination): Observable<HttpResponse<IUser[]>> {
    //     const options = createRequestOption(req);
    //     return this.http.get<IUser[]>(this.resourceUrl, { params: options, observe: 'response' });
    //   }

    //   queryAll(req?: Pagination): Observable<HttpResponse<IUser[]>> {
    //     const options = createRequestOption(req);
    //     return this.http.get<IUser[]>(`${this.resourceUrl}/all`, { params: options, observe: 'response' });
    //   }

    delete(login: string): Observable<{}> {
        return this.http.delete(`${this.resourceUrl}/${login}`);
    }

    authorities(): Observable<string[]> {
        return this.http.get<string[]>(this.repoURL + '/api/authorities');
    }

    findByEmergencyAgentToken(emergencyAgentToken: string): Observable<IUser> {
        return this.http.get<IUser>(
            `${this.resourceUrl}/emergency-agent-token/${emergencyAgentToken}`
        );
    }

    search(query: string): Observable<HttpResponse<IUser[]>> {
        return this.http.get<IUser[]>(
            `${this.resourceUrl}/search?query=${query}`,
            { observe: 'response' }
        );
    }
}
