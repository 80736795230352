import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import { LOGO } from './reporting-constants';
import { ReportingCommonService } from './reporting-common.service';
import { ReportingPage1Service } from './reporting-page1.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IReportData } from './report-data.model';
import { ReportingPage2Service } from './reporting-page2.service';
import { ReportingPage5Service } from './reporting-page5.service';
import { ReportingPage6Service } from './reporting-page6.service';
import { ReportingPage3Service } from './reporting-page3.service';
import { ReportingPage4Service } from './reporting-page4.service';
import { ReportingPage7Service } from './reporting-page7.service';
import { IUser } from 'app/modules/shared/models/user-management.model';
import { environment } from 'environments/environment';
import { Account } from 'app/modules/shared/models/account.model';
import { IBaseData } from './base-data.model';
import { IExternalContact } from 'app/modules/shared/models/external-contact.model';
import { EmergencyContactTypes } from 'app/modules/shared/models/emergency-contact-types.model';
import { GenericDTO } from 'app/modules/shared/models/generic-dto.model';

export type EntityResponseType = HttpResponse<IReportData>;

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  reportResponseData: IReportData | null = null;
  connectedUser: IUser | null = null;
  protected repoURL = environment.repoUrl;
  protected resourceUrl = this.repoURL + '/api/reports';
  protected resourceUrlForThePhoneApp = this.repoURL + '/api/user';
  private reportingResourceUrl = this.repoURL + '/api/reports';

  constructor(
    protected http: HttpClient,
    private reportingCommonService: ReportingCommonService,
    private reportingPage1Service: ReportingPage1Service,
    private reportingPage2Service: ReportingPage2Service,
    private reportingPage3Service: ReportingPage3Service,
    private reportingPage4Service: ReportingPage4Service,
    private reportingPage5Service: ReportingPage5Service,
    private reportingPage6Service: ReportingPage6Service,
    private reportingPage7Service: ReportingPage7Service
  ) {}

  queryAllReportDataAndDownloadReport(userId: number | undefined, account: Account | null, lastConfigurableBPDays: number): void {
    if (!userId) {
      return;
    }

    this.queryAllReportData(userId, lastConfigurableBPDays).subscribe(
      data => {
        this.reportResponseData = data.body;
        this.downloadReport(account, this.reportResponseData);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  queryAllReportDataAndDownloadReportForTheCareTaker(
    userId: number | undefined,
    account: Account | null,
    lastConfigurableBPDays: number
  ): void {
    if (!userId) {
      return;
    }

    this.queryAllReportDataForTheCareTaker(userId, lastConfigurableBPDays).subscribe(
      data => {
        this.reportResponseData = data.body;
        this.downloadReport(account, this.reportResponseData);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  queryAllReportData(userId: number, lastConfigurableBPDays: number): Observable<EntityResponseType> {
    return this.http.get<IReportData>(`${this.resourceUrl}/user/${userId}/bp-days/${lastConfigurableBPDays}`, { observe: 'response' });
  }

  queryAllReportDataForTheCareTaker(userId: number, lastConfigurableBPDays: number): Observable<EntityResponseType> {
    return this.http.get<IReportData>(`${this.resourceUrlForThePhoneApp}/${userId}/report/bp-days/${lastConfigurableBPDays}`, {
      observe: 'response',
    });
  }

  downloadReport(account: Account | null, reportResponseData: IReportData | null): void {
    const userData = reportResponseData?.user;
    const salutation = userData!.salutation != null ? userData!.salutation : '';
    const title = userData!.title != null ? userData!.title : '';
    const firstName = userData!.firstName != null ? userData!.firstName : '';
    const lastName = userData!.lastName != null ? userData!.lastName : '';
    const connectedUserFirsttame = account?.firstName ?? '';
    const connectedUserLastName = account?.lastName ?? '';
    const connectedUserFullName = connectedUserFirsttame + ' ' + connectedUserLastName;
    const reporter = connectedUserFullName === ' ' ? '' : ', ' + connectedUserFullName;

    const data = {
      generalData: {
        label: '21.2.1.1.A9 - report 1/version 1/A9' + '  © ALMA PHIL GmbH, Germany, www.almaphil.com',
        companyName: 'ALMA PHIL',
        title: salutation + ' ' + title + ' ',
        name: firstName + ' ' + lastName,
        age: userData!.age,
        date: this.todaysDate(),
        reporter: 'Uhr' + reporter,
      },
      page1: {
        column1: {
          Anrede: salutation,
          Titel: title,
          Vorname: firstName,
          Nachname: lastName,
          Geburtsdatum: userData!.birthday !== null ? userData!.birthday : '',
          Geschlecht: userData!.sex !== null ? userData!.sex : '',
          Muttersprache: userData!.firstLanguage !== null ? userData!.firstLanguage : '',
        },
        column2: {
          Straße: userData!.address?.street !== null ? userData!.address?.street : '',
          Hausnummer: userData!.address?.streetNumber !== null ? userData!.address?.streetNumber : '',
          PLZ: userData!.address?.zip !== null ? userData!.address?.zip : '',
          Ort: userData!.address?.city !== null ? userData!.address?.city : '',
          Stadtteil: userData!.address?.district !== null ? userData!.address?.district : '',
          Region: userData!.address?.region !== null ? userData!.address?.region : '',
          Land: userData!.address?.country !== null ? userData!.address?.country : '',
          Festnetz: userData!.phoneHome !== null ? userData!.phoneHome : '',
          Mobilnetz: userData!.phoneMobile !== null ? userData!.phoneMobile : '',
        },
        column3: {
          Betreuer: this.displayCareTakerContacts(reportResponseData?.careTakers ?? [], reportResponseData?.externalContacts ?? []),
          Pflegedienst: this.displayEmergencyCenterInfo(),
          Notfallkontakte: this.displayCareTakerContacts(
            reportResponseData?.emergencyContacts ?? [],
            reportResponseData?.emergencyExternalContacts ?? []
          ),
        },
        column4: {
          Vorerkrankungen: this.getPreExistingIllness(),
          Allergien: this.getAllergies(),
          Notrufe: this.displayEmergencyNumbers(),
          Ereignisse: this.displayNotes(),
          Behandlungshinweise:
            reportResponseData?.additionalUserData?.suggestedTreatment != null
              ? reportResponseData.additionalUserData.suggestedTreatment
              : '',
          'Vorlieben/Bemerkungen':
            reportResponseData?.additionalUserData?.preferences != null ? reportResponseData.additionalUserData.preferences : '',
        },
      },
    };

    const page2Data = {
      emptyCommitmentBlock: reportResponseData?.emptyCommitmentBlock,
      emptyDetailedMedicationBlock: reportResponseData?.emptyDetailedMedicationBlock,
      table1: {
        data: [
          [' ', ...(reportResponseData?.lastThirtyDayLabels != null ? reportResponseData.lastThirtyDayLabels : [])],
          [
            'Treue: Einnahme',
            ...(reportResponseData?.lastThirtyMedicamentEngagement != null ? reportResponseData.lastThirtyMedicamentEngagement : []),
          ],
          [
            'Treue: Blutdruck',
            ...(reportResponseData?.lastThirtyDaysBloodPressureMeasureEngagement != null
              ? reportResponseData.lastThirtyDaysBloodPressureMeasureEngagement
              : []),
          ],
          [
            'Treue: Puls',
            ...(reportResponseData?.lastThirtyDaysPulseMeasureEngagement != null
              ? reportResponseData.lastThirtyDaysPulseMeasureEngagement
              : []),
          ],
          [
            'Treue: Gewicht',
            ...(reportResponseData?.lastThirtyDaysWeightMeasureEngagement != null
              ? reportResponseData.lastThirtyDaysWeightMeasureEngagement
              : []),
          ],
          [
            'Treue: Bewegungsübungen',
            ...(reportResponseData?.lastThirtyDaysSportMeasureEngagement != null
              ? reportResponseData.lastThirtyDaysSportMeasureEngagement
              : []),
          ],
        ],
        // legends: ['94%', '86%', '95%', '100%'],
        legends: [...(reportResponseData?.lastThirtyDayEngagementAvg != null ? reportResponseData.lastThirtyDayEngagementAvg : [])],
        lastThirtyDayTotalEngagementAvg:
          reportResponseData?.lastThirtyDayTotalEngagementAvg != null ? reportResponseData.lastThirtyDayTotalEngagementAvg : 0,
      },
      table2: {
        data: [...(reportResponseData?.lastThirtyDayMedicamentDetails != null ? reportResponseData.lastThirtyDayMedicamentDetails : [])],
        legends: [
          ...(reportResponseData?.lastThirtyDayMedicamentDetailsAvg != null ? reportResponseData.lastThirtyDayMedicamentDetailsAvg : []),
        ],
      },
      table3: {
        stepsDailyTarget: reportResponseData?.stepsDailyTarget != null ? reportResponseData.stepsDailyTarget : 0,
        maxSteps: reportResponseData?.maxSteps != null ? reportResponseData.maxSteps : 0,
        minSteps: reportResponseData?.minSteps != null ? reportResponseData.minSteps : 0,
        avgSteps: reportResponseData?.avgSteps != null ? reportResponseData.avgSteps : 0,
        standardDeviation: reportResponseData?.standardDeviation != null ? reportResponseData.standardDeviation : '',
        // TODO: there is a pb with dataDelta graph is there is only big negative values
        dataDelta:
          reportResponseData?.lastThirtyDayStepsDeltaCountAsNumbers != null ? reportResponseData.lastThirtyDayStepsDeltaCountAsNumbers : [],
        data: [
          [' ', ...(reportResponseData?.lastThirtyDayLabels != null ? reportResponseData.lastThirtyDayLabels : [])],
          ['Schritte, #', ...(reportResponseData?.lastThirtyDayStepsData != null ? reportResponseData.lastThirtyDayStepsData : [])],
          [
            'Delta, #',
            ...(reportResponseData?.lastThirtyDayStepsDeltaCount != null ? reportResponseData.lastThirtyDayStepsDeltaCount : []),
          ],
          ['Delta %', ...(reportResponseData?.lastThirtyDayStepsDeltaRate != null ? reportResponseData.lastThirtyDayStepsDeltaRate : [])],
        ],
      },
    };

    const page3Data = {
      calendar: reportResponseData?.last12MonthsConvertedWellBeingIndexes,
      table1: {
        last30DayWellBeingIndexMin: reportResponseData?.last30DayWellBeingIndexMin,
        last30DayWellBeingIndexMax: reportResponseData?.last30DayWellBeingIndexMax,
        last30DayWellBeingIndexAvg: reportResponseData?.last30DayWellBeingIndexAvg,
        last30DayWellBeingIndexStdDev: reportResponseData?.last30DayWellBeingIndexStdDev,
        emptyWellBeingBlock: reportResponseData?.emptyWellBeingBlock,
        data: [
          [' ', ...(reportResponseData?.lastThirtyDayLabels != null ? reportResponseData.lastThirtyDayLabels : [])],
          [
            'Wohlbefinden (Skala 1-5)',
            ...(reportResponseData?.last30DayWellBeingIndexes != null ? reportResponseData.last30DayWellBeingIndexes : []),
          ],
          [
            'Wohlbefinden (ja/nein)',
            ...(reportResponseData?.last30DayWellBeingExtendedWell != null ? reportResponseData.last30DayWellBeingExtendedWell : []),
          ],
          [
            'Schwindel (ja/nein)',
            ...(reportResponseData?.last30DayWellBeingExtendedDizzy != null ? reportResponseData.last30DayWellBeingExtendedDizzy : []),
          ],
          [
            'Unbekannter Schmerz (ja/nein)',
            ...(reportResponseData?.last30DayWellBeingExtendedUnfamiliarPain != null
              ? reportResponseData.last30DayWellBeingExtendedUnfamiliarPain
              : []),
          ],
          [
            'Erschöpft (ja/nein)',
            ...(reportResponseData?.last30DayWellBeingExtendedExhausted != null
              ? reportResponseData.last30DayWellBeingExtendedExhausted
              : []),
          ],
          [
            'Schmerzen (ja/nein)',
            ...(reportResponseData?.last30DayWellBeingExtendedRestPains != null
              ? reportResponseData.last30DayWellBeingExtendedRestPains
              : []),
          ],
          [
            'Stress (ja/nein)',
            ...(reportResponseData?.last30DayWellBeingExtendedStressed != null
              ? reportResponseData.last30DayWellBeingExtendedStressed
              : []),
          ],
          [
            'NYHA-Stadium (1-4)',
            ...(reportResponseData?.last30DayWellBeingExtendedNyhaClassification != null
              ? reportResponseData.last30DayWellBeingExtendedNyhaClassification
              : []),
          ],
        ],
      },
      table2: {
        emptyWellBeingHeartFailureBlock: reportResponseData?.emptyWellBeingHeartFailureBlock,
        data: [
          [' ', ...(reportResponseData?.lastThirtyDayLabels != null ? reportResponseData.lastThirtyDayLabels : [])],
          [
            'Müdigkeit (ja/nein)', // More Down
            ...(reportResponseData?.last30DayWellBeingHeartFailureMoreDown != null
              ? reportResponseData.last30DayWellBeingHeartFailureMoreDown
              : []),
          ],
          [
            'Husten (ja/nein)', // Extra Pillow
            ...(reportResponseData?.last30DayWellBeingHeartFailureExtraPilow != null
              ? reportResponseData.last30DayWellBeingHeartFailureExtraPilow
              : []),
          ],
          [
            'Atemnot (ja/nein)', // Shortness Breath
            ...(reportResponseData?.last30DayWellBeingHeartFailureShortnessBreath != null
              ? reportResponseData.last30DayWellBeingHeartFailureShortnessBreath
              : []),
          ],
          [
            'Schwellungen (ja/nein)', // Swollen Legs
            ...(reportResponseData?.last30DayWellBeingHeartFailureSwollenLegs != null
              ? reportResponseData.last30DayWellBeingHeartFailureSwollenLegs
              : []),
          ],
          [
            'Nycturi (#Toilettengänge)', // Toilet Visits
            ...(reportResponseData?.last30DayWellBeingHeartFailureToiletVisits != null
              ? reportResponseData.last30DayWellBeingHeartFailureToiletVisits
              : []),
          ],
          // [
          //   'Gewicht kg',
          //   ...(reportResponseData?.last30DayWeightInKg != null
          //     ? reportResponseData.last30DayWeightInKg
          //     : []),
          // ],
          [
            'Gewicht 24h Delta, kg',
            ...(reportResponseData?.last30DayWeight24hDeltaInKg != null ? reportResponseData.last30DayWeight24hDeltaInKg : []),
          ],
          [
            'Gewicht 72h Delta, kg',
            ...(reportResponseData?.last30DayWeight72hDeltaInKg != null ? reportResponseData.last30DayWeight72hDeltaInKg : []),
          ],
          [
            'Gewicht 7-Tage Delta, kg',
            ...(reportResponseData?.last30DayWeight7DaysDeltaInKg != null ? reportResponseData.last30DayWeight7DaysDeltaInKg : []),
          ],
        ],
      },
    };

    const page4Data = {
      table1: {
        emptyWeightBlock: reportResponseData?.emptyWeightBlock,
        last30DayDeltaFromTargetWeightInKg: reportResponseData?.last30DayDeltaFromTargetWeightInKg,
        bodyWeightTargetInKg: reportResponseData?.bodyWeightTargetInKg,
        last30DayMaxWeightInKg: reportResponseData?.last30DayMaxWeightInKg,
        last30DayMinWeightInKg: reportResponseData?.last30DayMinWeightInKg,
        last30DayAvgWeightInKg: reportResponseData?.last30DayAvgWeightInKg,
        last30DayStdDevWeightInKg: reportResponseData?.last30DayStdDevWeightInKg,
        data: [
          [' ', ...(reportResponseData?.lastThirtyDayLabels != null ? reportResponseData.lastThirtyDayLabels : [])],
          ['Gewicht, kg', ...(reportResponseData?.last30DayWeightInKg != null ? reportResponseData.last30DayWeightInKg : [])],
          [
            'Delta Zielgewicht, kg',
            ...(reportResponseData?.last30DayDeltaFromTargetWeightInKg != null
              ? reportResponseData.last30DayDeltaFromTargetWeightInKg
              : []),
          ],
          [
            'Gewicht 24h Delta, kg',
            ...(reportResponseData?.last30DayWeight24hDeltaInKg != null ? reportResponseData.last30DayWeight24hDeltaInKg : []),
          ],
          [
            'Gewicht 72h Delta, kg',
            ...(reportResponseData?.last30DayWeight72hDeltaInKg != null ? reportResponseData.last30DayWeight72hDeltaInKg : []),
          ],
          [
            'Gewicht 7-Tage Delta, kg',
            ...(reportResponseData?.last30DayWeight7DaysDeltaInKg != null ? reportResponseData.last30DayWeight7DaysDeltaInKg : []),
          ],
          ['BMI, kg/m2', ...(reportResponseData?.last30DayBMI != null ? reportResponseData.last30DayBMI : [])],
        ],
      },

      calendar: reportResponseData?.last12MonthsConvertedWellBeingHFToToiletVisits,

      table2: {
        emptyDrinkBlock: reportResponseData?.emptyDrinkBlock,
        drinkDailyTarget: reportResponseData?.drinkDailyTarget,
        maxDrink: reportResponseData?.maxDrink,
        minDrink: reportResponseData?.minDrink,
        avgDrink: reportResponseData?.avgDrink,
        stdDevDrink: reportResponseData?.stdDevDrink,
        data: [
          [' ', ...(reportResponseData?.lastThirtyDayLabels != null ? reportResponseData.lastThirtyDayLabels : [])],
          ['Trinkmenge, l', ...(reportResponseData?.lastThirtyDayDrinkValues != null ? reportResponseData.lastThirtyDayDrinkValues : [])],
          ['Delta, l', ...(reportResponseData?.lastThirtyDayDrinkDelta != null ? reportResponseData.lastThirtyDayDrinkDelta : [])],
          ['Delta %', ...(reportResponseData?.lastThirtyDayDrinkDeltaRate != null ? reportResponseData.lastThirtyDayDrinkDeltaRate : [])],
        ],
      },
    };

    const page5Data = {
      data30DaysSystolic: reportResponseData?.listSystolic30Days != null ? reportResponseData.listSystolic30Days : [],
      data30DaysDiastolic: reportResponseData?.listDiastolic30Days != null ? reportResponseData.listDiastolic30Days : [],
      data30DaysPulse: reportResponseData?.listPulse30Days != null ? reportResponseData.listPulse30Days : [],
      table: [
        ['Indiv. Ziel, mmHg', ...(reportResponseData?.target30DayBP != null ? reportResponseData.target30DayBP : [])],
        ['Durchschnitt, mmHg', ...(reportResponseData?.avg30DayBP != null ? reportResponseData.avg30DayBP : [])],
        ['Delta', ...(reportResponseData?.deltaRate30DayBP != null ? reportResponseData.deltaRate30DayBP : [])],
        ['Median, mmHg', ...(reportResponseData?.median30DayBP != null ? reportResponseData.median30DayBP : [])],
        ['Std-Abweichung', ...(reportResponseData?.stdDeviation30DayBP != null ? reportResponseData.stdDeviation30DayBP : [])],
        ['Variationskoeffizient', ...(reportResponseData?.coeffVariation30DayBP != null ? reportResponseData.coeffVariation30DayBP : [])],
      ],
      xTicks30DaySystolicDeltaChart:
        reportResponseData?.xTicks30DaySystolicDeltaChart != null ? reportResponseData.xTicks30DaySystolicDeltaChart : [],
      xTicks30DayDiastolicDeltaChart:
        reportResponseData?.xTicks30DayDiastolicDeltaChart != null ? reportResponseData.xTicks30DayDiastolicDeltaChart : [],
      xTicks30DayPulseDeltaChart:
        reportResponseData?.xTicks30DayPulseDeltaChart != null ? reportResponseData.xTicks30DayPulseDeltaChart : [],
      xTicks24HoursPulseDeltaChart:
        reportResponseData?.xTicks30Day24HoursPulseDeltaChart != null ? reportResponseData.xTicks30Day24HoursPulseDeltaChart : [],
      xTicks30DayDruckDeltaChart:
        reportResponseData?.xTicks30DayDruckDeltaChart != null ? reportResponseData.xTicks30DayDruckDeltaChart : [],
      values30DaySystolicDeltaChart:
        reportResponseData?.values30DaySystolicDeltaChart != null ? reportResponseData.values30DaySystolicDeltaChart : [],
      values30DayDiastolicDeltaChart:
        reportResponseData?.values30DayDiastolicDeltaChart != null ? reportResponseData.values30DayDiastolicDeltaChart : [],
      values30DayPulseDeltaChart:
        reportResponseData?.values30DayPulseDeltaChart != null ? reportResponseData.values30DayPulseDeltaChart : [],
      values24HoursPulseDeltaChart:
        reportResponseData?.values30Day24HoursPulseDeltaChart != null ? reportResponseData.values30Day24HoursPulseDeltaChart : [],
      values30DayDruckDeltaChart:
        reportResponseData?.values30DayDruckDeltaChart != null ? reportResponseData.values30DayDruckDeltaChart : [],
    };

    const page6Data = {
      dataXDaysSystolic: reportResponseData?.listSystolicXDays != null ? reportResponseData.listSystolicXDays : [],
      dataXDaysDiastolic: reportResponseData?.listDiastolicXDays != null ? reportResponseData.listDiastolicXDays : [],
      dataXDaysPulse: reportResponseData?.listPulseXDays != null ? reportResponseData.listPulseXDays : [],
      table: [
        ['Indiv. Ziel, mmHg', ...(reportResponseData?.targetXDayBP != null ? reportResponseData.targetXDayBP : [])],
        ['Durchschnitt, mmHg', ...(reportResponseData?.avgXDayBP != null ? reportResponseData.avgXDayBP : [])],
        ['Delta', ...(reportResponseData?.deltaRateXDayBP != null ? reportResponseData.deltaRateXDayBP : [])],
        ['Median, mmHg', ...(reportResponseData?.medianXDayBP != null ? reportResponseData.medianXDayBP : [])],
        ['Std-Abweichung', ...(reportResponseData?.stdDeviationXDayBP != null ? reportResponseData.stdDeviationXDayBP : [])],
        ['Variationskoeffizient', ...(reportResponseData?.coeffVariationXDayBP != null ? reportResponseData.coeffVariationXDayBP : [])],
      ],
      xTicksXDaySystolicDeltaChart:
        reportResponseData?.xTicksXDaySystolicDeltaChart != null ? reportResponseData.xTicksXDaySystolicDeltaChart : [],
      xTicksXDayDiastolicDeltaChart:
        reportResponseData?.xTicksXDayDiastolicDeltaChart != null ? reportResponseData.xTicksXDayDiastolicDeltaChart : [],
      xTicksXDayPulseDeltaChart: reportResponseData?.xTicksXDayPulseDeltaChart != null ? reportResponseData.xTicksXDayPulseDeltaChart : [],
      xTicksXDay24HoursPulseDeltaChart:
        reportResponseData?.xTicksXDay24HoursPulseDeltaChart != null ? reportResponseData.xTicksXDay24HoursPulseDeltaChart : [],
      xTicksXDayDruckDeltaChart: reportResponseData?.xTicksXDayDruckDeltaChart != null ? reportResponseData.xTicksXDayDruckDeltaChart : [],
      valuesXDaySystolicDeltaChart:
        reportResponseData?.valuesXDaySystolicDeltaChart != null ? reportResponseData.valuesXDaySystolicDeltaChart : [],
      valuesXDayDiastolicDeltaChart:
        reportResponseData?.valuesXDayDiastolicDeltaChart != null ? reportResponseData.valuesXDayDiastolicDeltaChart : [],
      valuesXDayPulseDeltaChart: reportResponseData?.valuesXDayPulseDeltaChart != null ? reportResponseData.valuesXDayPulseDeltaChart : [],
      valuesXDay24HoursPulseDeltaChart:
        reportResponseData?.valuesXDay24HoursPulseDeltaChart != null ? reportResponseData.valuesXDay24HoursPulseDeltaChart : [],
      valuesXDayDruckDeltaChart: reportResponseData?.valuesXDayDruckDeltaChart != null ? reportResponseData.valuesXDayDruckDeltaChart : [],
      lastConfigurableBPDays: reportResponseData?.lastConfigurableBPDays != null ? reportResponseData.lastConfigurableBPDays : [],
    };

    const page7Data = {
      calendar: reportResponseData?.last12MonthsSystolicScaledValues,

      calendarBis: [
        {
          date: '2022-01-01T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-01-02T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-01-03T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-01-04T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-01-05T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-01-06T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-01-07T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-01-08T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-01-09T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-01-10T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-01-11T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-01-12T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-01-13T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-01-14T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-01-15T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-01-16T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-01-17T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-01-18T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-01-19T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-01-20T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-01-21T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-01-22T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-01-23T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-01-24T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-01-25T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-01-26T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-01-27T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-01-28T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-01-29T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-01-30T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-01-31T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-02-01T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-02-02T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-02-03T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-02-04T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-02-05T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-02-06T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-02-07T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-02-08T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-02-09T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-02-10T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-02-11T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-02-12T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-02-13T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-02-14T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-02-15T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-02-16T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-02-17T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-02-18T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-02-19T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-02-20T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-02-21T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-02-22T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-02-23T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-02-24T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-02-25T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-02-26T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-02-27T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-02-28T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-03-01T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-03-02T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-03-03T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-03-04T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-03-05T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-03-06T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-03-07T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-03-08T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-03-09T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-03-10T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-03-11T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-03-12T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-03-13T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-03-14T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-03-15T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-03-16T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-03-17T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-03-18T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-03-19T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-03-20T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-03-21T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-03-22T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-03-23T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-03-24T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-03-25T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-03-26T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-03-27T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-03-27T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-03-28T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-03-29T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-03-30T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-03-31T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-04-01T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-04-02T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-04-03T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-04-04T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-04-05T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-04-06T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-04-07T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-04-08T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-04-09T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-04-10T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-04-11T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-04-12T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-04-13T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-04-14T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-04-15T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-04-16T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-04-17T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-04-18T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-04-19T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-04-20T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-04-21T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-04-22T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-04-23T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-04-24T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-04-25T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-04-26T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-04-27T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-04-28T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-04-29T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-04-30T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-05-01T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-05-02T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-05-03T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-05-04T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-05-05T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-05-06T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-05-07T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-05-08T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-05-09T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-05-10T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-05-11T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-05-12T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-05-13T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-05-14T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-05-15T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-05-16T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-05-17T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-05-18T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-05-19T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-05-20T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-05-21T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-05-22T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-05-23T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-05-24T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-05-25T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-05-26T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-05-27T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-05-28T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-05-29T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-05-30T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-05-31T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-06-01T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-06-02T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-06-03T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-06-04T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-06-05T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-06-06T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-06-07T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-06-08T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-06-09T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-06-10T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-06-11T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-06-12T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-06-13T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-06-14T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-06-15T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-06-16T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-06-17T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-06-18T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-06-19T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-06-20T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-06-21T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-06-22T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-06-23T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-06-24T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-06-25T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-06-26T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-06-27T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-06-28T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-06-29T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-06-30T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-07-01T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-07-02T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-07-03T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-07-04T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-07-05T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-07-06T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-07-07T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-07-08T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-07-09T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-07-10T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-07-11T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-07-12T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-07-13T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-07-14T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-07-15T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-07-16T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-07-17T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-07-18T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-07-19T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-07-20T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-07-21T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-07-22T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-07-23T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-07-24T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-07-25T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-07-26T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-07-27T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-07-28T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-07-29T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-07-30T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-07-31T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-08-01T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-08-02T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-08-03T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-08-04T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-08-05T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-08-06T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-08-07T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-08-08T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-08-09T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-08-10T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-08-11T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-08-12T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-08-13T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-08-14T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-08-15T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-08-16T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-08-17T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-08-18T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-08-19T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-08-20T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-08-21T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-08-22T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-08-23T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-08-24T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-08-25T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-08-26T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-08-27T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-08-28T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-08-29T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-08-30T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-08-31T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-09-01T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-09-02T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-09-03T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-09-04T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-09-05T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-09-06T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-09-07T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-09-08T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-09-09T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-09-10T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-09-11T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-09-12T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-09-13T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-09-14T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-09-15T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-09-16T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-09-17T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-09-18T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-09-19T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-09-20T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-09-21T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-09-22T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-09-23T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-09-24T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-09-25T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-09-26T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-09-27T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-09-28T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-09-29T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-09-30T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-10-01T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-10-02T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-10-03T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-10-04T23:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-10-05T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-10-06T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-10-07T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-10-08T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-10-09T23:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-10-10T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-10-11T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-10-12T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-10-13T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-10-14T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-10-15T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-10-16T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-10-17T23:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-10-18T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-10-19T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-10-20T23:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-10-21T23:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-10-22T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-10-23T23:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-10-24T23:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-10-25T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-10-26T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-10-27T23:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-10-28T23:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-10-29T23:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-10-31T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-11-01T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-11-02T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-11-03T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-11-04T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-11-05T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-11-06T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-11-07T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-11-08T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-11-09T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-11-10T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-11-11T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-11-12T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-11-13T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-11-14T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-11-15T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-11-16T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-11-17T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-11-18T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-11-19T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-11-20T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-11-21T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-11-22T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-11-23T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-11-24T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-11-25T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-11-26T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-11-27T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-11-28T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-11-29T00:00:00.000Z',
          value: 5,
        },
        {
          date: '2022-11-30T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-12-01T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-12-02T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-12-03T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-12-04T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-12-05T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-12-06T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-12-07T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-12-08T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-12-09T00:00:00.000Z',
          value: 9,
        },
        {
          date: '2022-12-10T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-12-11T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-12-12T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-12-13T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-12-14T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-12-15T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-12-16T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-12-17T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-12-18T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-12-19T00:00:00.000Z',
          value: 6,
        },
        {
          date: '2022-12-20T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-12-21T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-12-22T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-12-23T00:00:00.000Z',
          value: 4,
        },
        {
          date: '2022-12-24T00:00:00.000Z',
          value: 2,
        },
        {
          date: '2022-12-25T00:00:00.000Z',
          value: 7,
        },
        {
          date: '2022-12-26T00:00:00.000Z',
          value: 8,
        },
        {
          date: '2022-12-27T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-12-28T00:00:00.000Z',
          value: 10,
        },
        {
          date: '2022-12-29T00:00:00.000Z',
          value: 3,
        },
        {
          date: '2022-12-30T00:00:00.000Z',
          value: 1,
        },
        {
          date: '2022-12-31T00:00:00.000Z',
          value: 3,
        },
      ],
    };

    const OFFSET_X = 30;
    const OFFSET_Y = 30;

    const factor = 1.25;
    const totalPage = 8;

    const { generalData } = data;
    // initialize a landscape jspdf document with pt unit
    const doc = new jsPDF('l', 'pt', [841.89 * factor, 595.28]);

    this.reportingCommonService.initializeFonts(doc);

    // set the font size
    doc.setFontSize(11);
    // set the font family
    this.reportingCommonService.setNormalFont;

    // generate 7 more pages of the report
    for (let i = 0; i < totalPage - 1; i++) {
      doc.addPage();
      doc.setFontSize(11);
      this.reportingCommonService.setNormalFont(doc);
      doc.setTextColor(0, 0, 0);
    }

    // add page numbers from page 1 to 8
    for (let i = 1; i <= totalPage; i++) {
      // add page number to the bottom right corner of the page
      doc.setPage(i);
      // doc.text('Seite ' + i, doc.internal.pageSize.width - OFFSET_X, doc.internal.pageSize.height - OFFSET_Y, 'right');
      doc.text(`Seite ${i}`, doc.internal.pageSize.width - OFFSET_X, doc.internal.pageSize.height - OFFSET_Y, { align: 'right' });

      doc.text(generalData.date, OFFSET_X, doc.internal.pageSize.height - OFFSET_Y);
      // get the width of the date text
      const dateWidth = doc.getTextWidth(generalData.date);
      // add reporter name to the right of the date
      doc.text(generalData.reporter, OFFSET_X + dateWidth + 2, doc.internal.pageSize.height - OFFSET_Y);

      // add title to the top left corner of the page
      doc.setFontSize(16);
      doc.text(generalData.title, OFFSET_X, OFFSET_Y);
      // get the width of the title text
      const titleWidth = doc.getTextWidth(generalData.title);
      // add name to the right of the title; make it bold
      this.reportingCommonService.setBoldFont(doc);
      doc.text(generalData.name, OFFSET_X + titleWidth + 2, OFFSET_Y);
      // set the font to normal
      const nameWidth = doc.getTextWidth(generalData.name);
      this.reportingCommonService.setNormalFont(doc);
      // add age to the right of the name
      doc.text(`, ${generalData.age ?? ''}`, OFFSET_X + titleWidth + nameWidth + 2, OFFSET_Y);

      doc.setFontSize(11);

      // add label to bottom right
      doc.setFontSize(8);
      doc.text(
        generalData.label,
        doc.internal.pageSize.width - OFFSET_X / 2,
        doc.internal.pageSize.height - OFFSET_Y * 2,
        { angle: 90 },
        90
      );

      // add logo to the top right corner of the page
      doc.addImage(LOGO, 'PNG', doc.internal.pageSize.width - 128 - OFFSET_X, 10, 130, 30);

      // set the font size back to 12
      doc.setFontSize(11);
      this.reportingCommonService.setNormalFont(doc);
    }

    let nbLastFilledPage = this.reportingPage1Service.generateReport(doc, data.page1);
    nbLastFilledPage = this.reportingPage2Service.render(doc, page2Data, nbLastFilledPage + 1);
    nbLastFilledPage = this.reportingPage3Service.render(doc, page3Data, nbLastFilledPage + 1);
    nbLastFilledPage = this.reportingPage4Service.render(doc, page4Data, nbLastFilledPage + 1);
    nbLastFilledPage = this.reportingPage5Service.render(doc, page5Data, nbLastFilledPage + 1);
    nbLastFilledPage = this.reportingPage6Service.render(doc, page6Data, nbLastFilledPage + 1);
    this.reportingPage7Service.render(doc, page7Data, nbLastFilledPage + 1);

    doc.save('Report.pdf');
  }

  todaysDate(): string {
    // return current date in format: 17.01.2024, 10:53
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}, ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  displayCareTakerContacts(emergencyContacts: IBaseData[], emergencyExternalContacts: IExternalContact[]): string[] {
    const contacts: string[] = [];
    emergencyContacts.forEach(contact => {
      let phone = '';
      if (contact.phoneMobile) {
        phone += ', ' + contact.phoneMobile;
      } else if (contact.phoneHome) {
        phone += ', ' + contact.phoneHome;
      }
      const contactData = `${contact.firstName!} ${contact.lastName!}${phone}`;
      contacts.push(contactData);
    });

    emergencyExternalContacts.forEach(contact => {
      let phone = '';
      if (contact.phoneMobile) {
        phone += ', ' + contact.phoneMobile;
      } else if (contact.phoneHome) {
        phone += ', ' + contact.phoneHome;
      }
      const contactData = `${contact.firstname!} ${contact.lastname!}${phone}`;
      contacts.push(contactData);
    });
    return contacts;
  }

  displayEmergencyCenterInfo(): string {
    let infoToDisplay = '';
    const emergencyCenter = this.reportResponseData?.emergencyCenter;
    if (emergencyCenter?.enabled) {
      if (emergencyCenter.userEmergencyCenterProfile) {
        infoToDisplay += emergencyCenter.userEmergencyCenterProfile.profileName;
        if (emergencyCenter.userEmergencyCenterProfile.phoneNumber) {
          infoToDisplay += ', ' + emergencyCenter.userEmergencyCenterProfile.phoneNumber;
        }
      } else {
        const emergencyCenterType = emergencyCenter.emergencyContactType;
        if (emergencyCenterType === EmergencyContactTypes.HOME_EMERGENCY_CALL_CENTER) {
          infoToDisplay += emergencyCenter.phone;
        } else if (emergencyCenterType === EmergencyContactTypes.RESCUE_CENTER) {
          infoToDisplay += emergencyCenter.phoneRescueCenter;
        }
      }
    }
    return infoToDisplay;
  }

  getPreExistingIllness(): string {
    const preExistingIllness = [];
    const cardioSettings = this.reportResponseData?.cardioSettings;

    if (cardioSettings?.hasHypertension) {
      preExistingIllness.push('Bluthochdruck');
    }

    if (cardioSettings?.hasHeartFailure) {
      preExistingIllness.push('Herzinssufizienz');
    }

    if (cardioSettings?.hasHeartDisease) {
      preExistingIllness.push('Herzkranzleiden');
    }

    if (cardioSettings?.hasDiabetes) {
      preExistingIllness.push('Diabetes');
    }

    if (cardioSettings?.hasKidneyDisease) {
      preExistingIllness.push('Chronische Nierenleiden');
    }

    return preExistingIllness.join(', ') || 'Keine bekannt';
  }

  getAllergies(): string {
    const allergyList: string[] = [];
    const allergies = this.reportResponseData?.allergies;
    if (allergies) {
      allergies.forEach(allergy => {
        allergyList.push(allergy);
      });
    }
    return allergyList.join(', ') || 'Keine bekannt';
  }

  getThisYear(): number {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

  getLastYear(): number {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    return previousYear;
  }

  displayEmergencyNumbers(): string {
    let toDisplay = '';
    toDisplay += this.reportResponseData?.lastYearEmergencyNumber;
    toDisplay += ' (';
    toDisplay += this.getLastYear();
    toDisplay += ') / ';
    toDisplay += this.reportResponseData?.thisYearEmergencyNumber;
    toDisplay += ' (';
    toDisplay += this.getThisYear();
    toDisplay += ')';
    return toDisplay;
  }

  displayNotes(): string[] {
    const notes = this.reportResponseData?.notes;
    const strNotes: string[] = [];
    if (notes) {
      notes.forEach(note => {
        let oneLineNote = '';
        if (note.createdAt) {
          oneLineNote += note.createdAt.toString() + ' ';
        }
        if (note.text) {
          oneLineNote += note.text;
        }
        strNotes.push(oneLineNote);
      });
    }
    return strNotes;
  }

  queryAllReportDataAndDownloadReportByPhoneAccessToken(
    account: Account | null,
    phoneAccessToken: string,
    lastConfigurableBPDays: number
  ): void {
    if (!phoneAccessToken) {
      return;
    }

    this.queryAllReportDataByPhoneAccessToken(phoneAccessToken, lastConfigurableBPDays).subscribe(
      data => {
        const reportResponseData = data.body;
        this.downloadReport(account, reportResponseData);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  queryAllReportDataByPhoneAccessToken(phoneAccessToken: string, lastConfigurableBPDays: number): Observable<HttpResponse<IReportData>> {
    return this.http.get<IReportData>(
      `${this.reportingResourceUrl}/phone-access-token/${phoneAccessToken}/bp-days/${lastConfigurableBPDays}`,
      { observe: 'response' }
    );
  }

  getUserRequestingReportName(phoneAccessToken: string): Observable<HttpResponse<GenericDTO>> {
    return this.http.get<GenericDTO>(`${this.reportingResourceUrl}/phone-access-token/${phoneAccessToken}/user-requesting-report-name`, {
      observe: 'response',
    });
  }
}
