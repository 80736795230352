import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportingCommonService } from './reporting-common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingPage2Service {
  constructor(private reportingCommonService: ReportingCommonService) {}

  render(doc: jsPDF, data: any, nbPage: number): number {
    doc.setPage(nbPage);

    // draw bold title
    this.reportingCommonService.setBoldFont(doc);
    let title = 'Adhärenz';
    doc.setFontSize(14);
    doc.text(title, 42, 70);

    let pageHeight = doc.internal.pageSize.height - 100;
    let x = 30;

    // draw table 1
    // let tableY = 85;
    let tableY = 115;
    if (data.table1.data.length > 0) {
      this.reportingCommonService.drawTable(
        doc,
        {
          x: 42,
          y: tableY,
          title1: 'Treue',
          title2: '',
          indicators: [
            { name: 'Ziel', value: '100%' },
            { name: 'Durchschnitt', value: data.table1.lastThirtyDayTotalEngagementAvg },
          ],
          data: data.table1.data,
          legends: data.table1.legends,
          colorTableCell(cellValue: string, rowNumber: number) {
            let color = [255, 255, 255];
            if (rowNumber !== 0) {
              if (cellValue.includes('%') && cellValue < '100%') {
                color = [232, 162, 177]; // red if less than 100%
              }
            }
            return color;
          },
        },
        data.emptyCommitmentBlock
      );
      // tableY = 180;
      tableY = 280;
    }

    const column1Width = 200;
    const tableX = 42;

    const lineHeight = 16;
    const [periods, dosages, tableData] = this.extractDosage(data.table2.data);
    let periodsOverflow: string | any[] = [];
    let dosagesOverflow: number[] = [];
    let tableDataOverflow: string | any[] = [];
    let legendsOverflow: string[] = [];

    // draw table 2
    this.reportingCommonService.drawTable(
      doc,
      {
        x: tableX,
        y: tableY,
        title1: 'Einzelauswertungen Medikamente',
        title2: '',
        data: tableData,
        lineHeight,
        column1Width,
        legends: data.table2.legends,
        rowDrawListener(index: number, y: number) {
          if (y > doc.internal.pageSize.height - 60) {
            tableDataOverflow = tableData.splice(index);
            dosagesOverflow = dosages.splice(index);
            periodsOverflow = periods.splice(index);
            legendsOverflow = data.table2.legends.splice(index);
            return false;
          }
          return true;
        },
        colorTableCell(cellValue: string, rowNumber: number) {
          let color = [255, 255, 255];
          if (rowNumber !== 0) {
            color = cellValue.includes('/') ? [232, 162, 177] : [173, 199, 147];
          }
          return color;
        },
      },
      data.emptyDetailedMedicationBlock
    );

    const rowHeight = 17; // 16 by default, adding 1 because of the bottom line
    this.reportingCommonService.addVerticalTexts(doc, periods, tableX + column1Width * 0.4, tableY + lineHeight * 0.5 - 2, lineHeight);
    // const periodArrayLenght = periods.length != null ? periods.length : 0;
    const periodArrayLenght = parseInt(periods.length, 10);
    doc.line(
      tableX + column1Width * 0.36,
      tableY + lineHeight + lineHeight / 2,
      tableX + column1Width * 0.36,
      tableY + lineHeight * (periodArrayLenght + 0.5)
    );
    this.reportingCommonService.addVerticalTexts(doc, dosages, tableX + column1Width * 0.7, tableY + lineHeight * 0.5 - 2, lineHeight);
    doc.line(
      tableX + column1Width * 0.64,
      tableY + lineHeight + lineHeight / 2,
      tableX + column1Width * 0.64,
      tableY + lineHeight * (periodArrayLenght + 0.5)
    );

    // add a rectangle
    // let rectY = tableY + periods.length * rowHeight - 22;
    // rectY = Math.min(rectY, doc.internal.pageSize.height - 90);
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    // doc.rect(x, 45, doc.internal.pageSize.width - x * 2, rectY);
    doc.rect(x, 45, doc.internal.pageSize.width - x * 2, pageHeight);

    const newPage = tableData.length > 14;

    let startY = pageHeight / 2 + 72;
    if (newPage) {
      nbPage = nbPage + 1;
      doc.setPage(nbPage);
      startY = 65;
      // if there is table overflow
      if (tableDataOverflow.length > 0) {
        tableY = 45;
        const legendsOverflow2: string[] = ['', ...legendsOverflow];
        this.reportingCommonService.drawTable(
          doc,
          {
            x: tableX,
            y: tableY,
            data: [tableData[0], ...tableDataOverflow],
            lineHeight,
            column1Width,
            legends: legendsOverflow2,
            rowDrawListener(index: number, y: number) {
              if (y > doc.internal.pageSize.height - 60) {
                tableDataOverflow = tableData.splice(index);
                dosagesOverflow = dosages.splice(index);
                periodsOverflow = periods.splice(index);
                legendsOverflow = data.table2.legends.splice(index);
                return false;
              }
              return true;
            },
            colorTableCell(cellValue: string, rowNumber: number) {
              let color = [255, 255, 255];
              if (rowNumber !== 0) {
                color = cellValue.includes('/') ? [232, 162, 177] : [173, 199, 147];
              }
              return color;
            },
          },
          data.emptyDetailedMedicationBlock
        );

        this.reportingCommonService.addVerticalTexts(
          doc,
          ['', ...periodsOverflow],
          tableX + column1Width * 0.4,
          tableY + lineHeight * 0.5 - 2,
          lineHeight
        );
        doc.line(
          tableX + column1Width * 0.36,
          tableY + lineHeight + lineHeight / 2,
          tableX + column1Width * 0.36,
          tableY + lineHeight * (periodsOverflow.length + 1.5)
        );
        this.reportingCommonService.addVerticalTexts(
          doc,
          ['', ...dosagesOverflow],
          tableX + column1Width * 0.7,
          tableY + lineHeight * 0.5 - 2,
          lineHeight
        );
        doc.line(
          tableX + column1Width * 0.64,
          tableY + lineHeight + lineHeight / 2,
          tableX + column1Width * 0.64,
          tableY + lineHeight * (periodsOverflow.length + 1.5)
        );

        // add a rectangle
        // rectY = tableY + tableDataOverflow.length * rowHeight - 12;
        doc.setDrawColor(0, 0, 0);
        doc.setFillColor(255, 255, 255);
        doc.setLineWidth(1);
        doc.rect(x, 45, doc.internal.pageSize.width - x * 2, pageHeight);
        startY = tableY + tableDataOverflow.length * rowHeight + 75;
      }
    }

    // page 2 was expanded into page 2 and 3
    nbPage = nbPage + 1;
    doc.setPage(nbPage);
    pageHeight = doc.internal.pageSize.height - 100;
    x = 30;
    tableY = 115;
    startY = 72;

    // draw delta graph + table 3
    // add a rectangle
    x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    pageHeight = doc.internal.pageSize.height - 100;
    doc.rect(x, startY - 25, doc.internal.pageSize.width - x * 2, pageHeight);

    this.reportingCommonService.setBoldFont(doc);
    doc.setFontSize(14);
    title = 'Schritte,';
    const textWidth = doc.getTextWidth(title);
    doc.text(title, tableX, startY);
    this.reportingCommonService.setNormalFont(doc);
    doc.setFontSize(10);
    doc.text('letzte 30 Tage', tableX + textWidth + 5, startY);

    // draw indicators to right
    const indicators = [
      { name: 'Ziel', value: data.table3.stepsDailyTarget },
      { name: 'Max', value: data.table3.maxSteps },
      { name: 'Min', value: data.table3.minSteps },
      { name: 'Durchschnitt', value: data.table3.avgSteps },
      { name: 'Std.Abw.', value: data.table3.standardDeviation },
    ];
    this.reportingCommonService.drawIndicator(doc, doc.internal.pageSize.width - 80, startY - 5, indicators);

    if (
      ((data.table3.stepsDailyTarget === '0' || data.table3.stepsDailyTarget === '') && data.table3.avgSteps === '0') ||
      data.table3.avgSteps === ''
    ) {
      return nbPage;
    }

    startY += 25;
    this.reportingCommonService.setBoldFont(doc);
    doc.text('Schritte, Delta zum täglichen Ziel', tableX, startY + lineHeight);

    startY += 15;
    // bar chart
    this.reportingCommonService.addDeltaChart(
      doc,
      data.table3.dataDelta, // data
      tableX + 100, // x
      startY + 40, // y
      doc.internal.pageSize.width - x * 2 - 160, // width
      60, // height
      [174, 198, 228], // fillColor
      false, // addTicks,
      '',
      '',
      []
    );

    this.reportingCommonService.drawTable(doc, {
      x: 42,
      y: startY + 240,
      title1: '',
      title2: '',
      data: data.table3.data,
    });

    return nbPage;
  }

  /**
   *
   * @param d
   * @returns
   */
  extractDosage(d: any[]): any[] {
    const tableData: any[] = [];
    const periods: any[] = [];
    const dosages: any[] = [];

    d.forEach(element => {
      periods.push(element[1]);
      element.splice(1, 1);
      dosages.push(element[1]);
      element.splice(1, 1);
      tableData.push(element);
    });

    return [periods, dosages, tableData];
  }
}
