
export interface IUrlShortened {
  id?: number;
  urlOriginal?: string | null;
  urlShortened?: string | null;
  dateCreation?: any | null;
}

export class UrlShortened implements IUrlShortened {
  constructor(
    public id?: number,
    public urlOriginal?: string | null,
    public urlShortened?: string | null,
    public dateCreation?: any | null
  ) {}
}

export function getUrlShortenedIdentifier(urlShortened: IUrlShortened): number | undefined {
  return urlShortened.id;
}
