import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportingCommonService } from './reporting-common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingPage7Service {
  constructor(private reportingCommonService: ReportingCommonService) {}

  render(doc: jsPDF, data: any, nbPage: number): number {
    const { calendar } = data;
    doc.setPage(nbPage);

    let y = 68;
    // draw the page title
    doc.setFontSize(14);
    this.reportingCommonService.setBoldFont(doc);
    doc.text('Systolischer (oberer) Blutdruck, relativ zum Zielkorridor mit Skala, letzte 12 Monate', 42, y);

    // const colors = ["#FFFFFF", "#E7F4E5", "#BEE2B8", "#9BD291", "#7DC571","#90C070", "#FACAE6", "#ADA267", "#BB7F5E", "#C6454E", "#CA023B"];
    const colors = [
      '#F8F8F8', // default background
      '#FFFFFF', // white
      '#E7F4E5', // green Mint Cream
      '#BEE2B8', // green Granny Smith Apple
      '#9BD291', // green Pistachio
      '#7DC571', // green Light Green
      '#90C070', // green Green Yellow
      // "#FACAE6", "#ADA267", "#BB7F5E", "#C6454E", "#CA023B"
      '#b893bd', // purpe level 1
      '#8f6695', // purpe level 2
      '#623968', // purpe level 3
      '#38183d', // purpe level 4
    ];

    this.reportingCommonService.Calendar2(calendar, doc, '%b', {
      x: (m: { date: string; value: number }) => new Date(m.date),
      y: (m: { date: string; value: number }) => m.value,
      cellSize: 15,
      xPosition: 48,
      yPosition: y + 30,
      useHexConverter: true,
      // colorScale: (d: number)=>colors[(+d-1)] /////
      colorScale: (d: number) => colors[+d],
      // colorScale: d3.scaleOrdinal<number, string>().domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).range(colors),
    });

    const x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    doc.rect(x, 50, doc.internal.pageSize.width - x * 2, doc.internal.pageSize.height - 102);

    y = 70;
    const legend_x = 870;
    doc.setFontSize(12);
    doc.text('Legende:', legend_x, y);

    const legends = ['•  Rot geht nach oben', '•  grün ist Ziel +/-3%', '•  weiß nach unten'];
    y += 5;
    this.reportingCommonService.setNormalFont(doc);
    legends.forEach(legend => {
      doc.text(legend, legend_x, (y += 15));
    });
    // // doc.save('report.pdf');
    // const iframe = document.getElementById('pdf_iframe');
    // //iframe.src = doc.output('bloburl');
    // // /////// doc.save("Result.pdf") /////

    return nbPage;
  }
}
