import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGeoFence } from 'app/modules/shared/models/geo-fence.model';
import { environment } from 'environments/environment';

// import { isPresent } from 'app/core/util/operators';
// import { ApplicationConfigService } from 'app/core/config/application-config.service';
// import { createRequestOption } from 'app/core/request/request-util';
// import { IGeoFence, getGeoFenceIdentifier } from '../geo-fence.model';

export type EntityResponseType = HttpResponse<IGeoFence>;
export type EntityArrayResponseType = HttpResponse<IGeoFence[]>;

@Injectable({ providedIn: 'root' })
export class GeoFenceService {
    protected repoURL = environment.repoUrl;
    protected resourceUrl = this.repoURL + '/api/geo-fences';

    constructor(protected http: HttpClient) {}

    create(geoFence: IGeoFence): Observable<EntityResponseType> {
        return this.http.post<IGeoFence>(this.resourceUrl, geoFence, {
            observe: 'response',
        });
    }

    //   update(geoFence: IGeoFence): Observable<EntityResponseType> {
    //     return this.http.put<IGeoFence>(`${this.resourceUrl}/${getGeoFenceIdentifier(geoFence) as number}`, geoFence, { observe: 'response' });
    //   }

    //   partialUpdate(geoFence: IGeoFence): Observable<EntityResponseType> {
    //     return this.http.patch<IGeoFence>(`${this.resourceUrl}/${getGeoFenceIdentifier(geoFence) as number}`, geoFence, {
    //       observe: 'response',
    //     });
    //   }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<IGeoFence>(`${this.resourceUrl}/${id}`, {
            observe: 'response',
        });
    }

    findGeofencesByEmergencyAgentToken(
        emergencyAgentToken: string
    ): Observable<EntityArrayResponseType> {
        return this.http.get<IGeoFence[]>(
            `${this.resourceUrl}/emergency-agent-token/${emergencyAgentToken}`,
            { observe: 'response' }
        );
    }

    //   query(req?: any): Observable<EntityArrayResponseType> {
    //     const options = createRequestOption(req);
    //     return this.http.get<IGeoFence[]>(this.resourceUrl, { params: options, observe: 'response' });
    //   }

    //   queryAll(req?: any): Observable<EntityArrayResponseType> {
    //     const options = createRequestOption(req);
    //     return this.http.get<IGeoFence[]>(`${this.resourceUrl}/all`, { params: options, observe: 'response' });
    //   }

    delete(id: number): Observable<HttpResponse<{}>> {
        return this.http.delete(`${this.resourceUrl}/${id}`, {
            observe: 'response',
        });
    }

    //   addGeoFenceToCollectionIfMissing(geoFenceCollection: IGeoFence[], ...geoFencesToCheck: (IGeoFence | null | undefined)[]): IGeoFence[] {
    //     const geoFences: IGeoFence[] = geoFencesToCheck.filter(isPresent);
    //     if (geoFences.length > 0) {
    //       const geoFenceCollectionIdentifiers = geoFenceCollection.map(geoFenceItem => getGeoFenceIdentifier(geoFenceItem)!);
    //       const geoFencesToAdd = geoFences.filter(geoFenceItem => {
    //         const geoFenceIdentifier = getGeoFenceIdentifier(geoFenceItem);
    //         if (geoFenceIdentifier == null || geoFenceCollectionIdentifiers.includes(geoFenceIdentifier)) {
    //           return false;
    //         }
    //         geoFenceCollectionIdentifiers.push(geoFenceIdentifier);
    //         return true;
    //       });
    //       return [...geoFencesToAdd, ...geoFenceCollection];
    //     }
    //     return geoFenceCollection;
    //   }
}
