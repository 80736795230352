import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { SplashScreenComponent } from './modules/shared/containers/splash-screen/splash-screen.component';
import { ConfigurationService } from './modules/shared/services/configuration.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, SplashScreenComponent],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private _config: ConfigurationService,
        private _authService: AuthService
    ) {
        console.log('*** init App component ***');
        this._config.setConfiguration();

        const pathname = window.location.pathname;
        const publicRoutes = [
            '/emergency-center-agent-access',
            '/tunstall-emergency-list',
            '/ext/wifi-setting',
        ];
        const shortUrl = '/s/';

        if (!pathname?.includes(shortUrl)) {
            if (publicRoutes?.includes(pathname)) {
                console.log('public');
            } else {
                console.log('login');
                _authService.configureSingleSignOn();
            }
        }
    }
}
