<div class="flex flex-auto absolute inset-0">
    @if (userWithEmergency) {
    <div class="flex flex-col flex-auto min-w-0 bg-card">
        <ng-container *transloco="let t">
            <mat-drawer-container
                class="flex-auto h-full"
                [hasBackdrop]="false"
            >
                <!-- Drawer -->
                <mat-drawer
                    class="w-full sm:w-120 lg:border-l lg:shadow-none dark:bg-gray-900"
                    [autoFocus]="false"
                    [mode]="drawerMode"
                    [position]="'end'"
                    [(opened)]="drawerOpened"
                    #drawer
                >
                    <!-- Group chat -->

                    @if(drawerOpened){
                    <!-- <conversation [drawer]="drawer" [user]="patient"></conversation> -->
                    <group-chat
                        [drawer]="drawer"
                        [chatMessages]="groupChatMessages"
                        (sendMessageEvent)="sendMessage($event)"
                    ></group-chat>
                    }
                </mat-drawer>

                <!-- Drawer content -->
                <mat-drawer-content class="flex flex-col overflow-hidden">
                    <!-- Header -->
                    <div
                        class="flex flex-0 items-center h-18 px-4 md:px-6 border-b bg-gray-50 dark:bg-transparent"
                    >
                        <!-- Back button -->
                        <!-- <a
                        class="lg:hidden md:-ml-2"
                        mat-icon-button
                        [routerLink]="['./']"
                        (click)="resetChat()"
                    >
                        <mat-icon
                            [svgIcon]="'heroicons_outline:arrow-long-left'"
                        ></mat-icon>
                    </a> -->

                        <!-- Contact info -->
                        <!-- (click)="openContactInfo()" -->
                        <div class="flex items-center ml-2 lg:ml-0 mr-2">
                            <!-- <div
                                class="relative flex flex-0 items-center justify-center w-10 h-10"
                            >
                                <div
                                    class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                                >
                                    {{ userWithEmergency.firstName[0] }}
                                </div>
                            </div> -->
                            <div
                                class="ml-4 text-lg font-medium leading-5 truncate"
                            >
                                {{ userWithEmergency.firstName }}
                                {{ userWithEmergency.lastName }},
                                {{ userEmergencyId?.value }}
                            </div>
                        </div>

                        <div class="ml-auto">
                            <div class="flex items-center sm:ml-2 space-x-3">
                                <!-- <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="updateGeolocationDisplay()"
                                    [disabled]="!isGeolocationUpdateEnabled()"
                                >
                                    <fa-icon icon="map-marked-alt"></fa-icon
                                    >&nbsp;Ort aktualisieren
                                </button> -->

                                <button
                                    mat-flat-button
                                    type="button"
                                    color="primary"
                                    (click)="displayHealthReport()"
                                >
                                    <mat-icon>report</mat-icon>
                                    <span class="hidden md:block">
                                        {{ t("HEALTH-REPORT") }}
                                    </span>
                                </button>

                                <button
                                    mat-flat-button
                                    type="button"
                                    color="primary"
                                    (click)="reEstablishTheCall()"
                                >
                                    <!-- <mat-icon>headphones</mat-icon> -->
                                    <mat-icon>phone</mat-icon>
                                    <span class="hidden md:block">
                                        Stellen Sie die Verbindung erneut her
                                    </span>
                                </button>

                                <button
                                    mat-flat-button
                                    type="button"
                                    color="primary"
                                    (click)="openGroupChat()"
                                >
                                    <mat-icon>chat</mat-icon>
                                    <span class="hidden md:block">
                                        {{ t("EMERGENCY-CHAT") }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col flex-1 overflow-auto p-8 bg-card">
                        <!-- <div class="text-3xl font-semibold">
                            {{ emergencyReason() }}
                        </div> -->

                        @if (reEstablishTheCallResult !== -22) {
                        <div class="flex justify-center mb-2">
                            <p class="text-md text-green-400">
                                Falls ein Teilnehmer von diesem Anruf
                                ausgeschlossen wurde, haben wir ihn einfach
                                erneut angerufen.
                            </p>
                        </div>
                        }

                        <!-- locations -->
                        @if(lastGeolocations?.length){
                        <div class="justify-center">
                            <google-map
                                height="600px"
                                width="100%"
                                [options]="options"
                            >
                                @for (location of lastGeolocations; track
                                location; let index = $index) {
                                <!-- marker -->
                                <map-advanced-marker
                                    #markerElem="mapAdvancedMarker"
                                    [position]="{
                                        lat: location.latitude,
                                        lng: location.longitude
                                    }"
                                    [content]="location.content"
                                    [title]="'Location ' + (index + 1)"
                                    (mapClick)="
                                        onMarkerClick(markerElem, location)
                                    "
                                />
                                <!-- marker -->
                                }
                                <map-info-window #infoWindow></map-info-window>
                                <map-circle [options]="circleOptions" />
                                <map-polyline [path]="lastGeolocations" />
                            </google-map>

                            <!-- <google-map
                                height="600px"
                                width="100%"
                                [center]="markerPositions[0]"
                                [options]="options"
                            >
                                @for (location of markerPositions; track
                                location; let i = $index) {
                                <map-advanced-marker
                                    #markerElem="mapAdvancedMarker"
                                    [position]="location"
                                    [label]="
                                        getMarkerLabel(
                                            markerPositions.length - (i + 1),
                                            markerPositions.length
                                        )
                                    "
                                    (mapClick)="mapMarkedClicked(markerElem, i)"
                                />
                                }

                                @if(showAccuracy){
                                <div>
                                    @for (circle of circlePositions; track
                                    circle; let i = $index) {
                                    <div>
                                        @if(selectedMarkerIndex !== null &&
                                        selectedMarkerIndex === i){
                                        <div>
                                            <map-circle
                                                [center]="circle.getCenter()"
                                                [radius]="circle.getRadius()"
                                            ></map-circle>
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                                }

                                <map-info-window
                                    #infoWindow
                                    [innerHTML]="infoContent"
                                ></map-info-window>

                                @for (place of markerGeofenceCenters; track
                                $index; let i = $index) {
                                <map-advanced-marker
                                    #markerGeoPosition="mapMarker"
                                    [icon]="mIcon"
                                    [position]="place"
                                    [options]="markerOptions"
                                    (mapClick)="
                                        openInfoWindowGeoFence(
                                            markerGeoPosition,
                                            i
                                        )
                                    "
                                />
                                }

                                @for (circle of circleGeofences; track $index) {
                                <map-circle
                                    [center]="circle.getCenter()"
                                    [radius]="circle.getRadius()"
                                    [options]="geofenceCircleOptions"
                                >
                                </map-circle>
                                }

                                <map-info-window
                                    [innerHTML]="infoContent"
                                ></map-info-window>
                            </google-map> -->
                        </div>
                        }
                    </div>
                </mat-drawer-content>
            </mat-drawer-container>
        </ng-container>
    </div>
    }
</div>
