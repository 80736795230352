import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { createRequestOption } from 'app/core/request/request-util';
import { getUrlShortenedIdentifier, IUrlShortened } from 'app/modules/shared/models/url-shortend.model';
import { DateTime } from 'luxon';
import { environment } from 'environments/environment';
import { isPresent } from 'app/modules/shared/services/util.service';

export type EntityResponseType = HttpResponse<IUrlShortened>;
export type EntityArrayResponseType = HttpResponse<IUrlShortened[]>;

@Injectable({ providedIn: 'root' })
export class UrlShortenedService {
  protected repoURL = environment.repoUrl;
  protected resourceUrl = this.repoURL + '/api/url-shorteneds';

  constructor(protected http: HttpClient) {}

  create(urlShortened: IUrlShortened): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(urlShortened);
    return this.http
      .post<IUrlShortened>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(urlShortened: IUrlShortened): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(urlShortened);
    return this.http
      .put<IUrlShortened>(`${this.resourceUrl}/${getUrlShortenedIdentifier(urlShortened) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(urlShortened: IUrlShortened): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(urlShortened);
    return this.http
      .patch<IUrlShortened>(`${this.resourceUrl}/${getUrlShortenedIdentifier(urlShortened) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IUrlShortened>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByCode(code: string): Observable<IUrlShortened> {
    return this.http.get<IUrlShortened>(`${this.resourceUrl}/code/${code}`);
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IUrlShortened[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addUrlShortenedToCollectionIfMissing(
    urlShortenedCollection: IUrlShortened[],
    ...urlShortenedsToCheck: (IUrlShortened | null | undefined)[]
  ): IUrlShortened[] {
    const urlShorteneds: IUrlShortened[] = urlShortenedsToCheck.filter(isPresent);
    if (urlShorteneds.length > 0) {
      const urlShortenedCollectionIdentifiers = urlShortenedCollection.map(
        urlShortenedItem => getUrlShortenedIdentifier(urlShortenedItem)!
      );
      const urlShortenedsToAdd = urlShorteneds.filter(urlShortenedItem => {
        const urlShortenedIdentifier = getUrlShortenedIdentifier(urlShortenedItem);
        if (urlShortenedIdentifier == null || urlShortenedCollectionIdentifiers.includes(urlShortenedIdentifier)) {
          return false;
        }
        urlShortenedCollectionIdentifiers.push(urlShortenedIdentifier);
        return true;
      });
      return [...urlShortenedsToAdd, ...urlShortenedCollection];
    }
    return urlShortenedCollection;
  }

  protected convertDateFromClient(urlShortened: IUrlShortened): IUrlShortened {
    return Object.assign({}, urlShortened, {
      dateCreation: urlShortened.dateCreation?.isValid() ? urlShortened.dateCreation.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateCreation = res.body.dateCreation ? DateTime.fromISO(res.body.dateCreation) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((urlShortened: IUrlShortened) => {
        urlShortened.dateCreation = urlShortened.dateCreation ? DateTime.fromISO(urlShortened.dateCreation) : undefined;
      });
    }
    return res;
  }
}
