import { Injectable } from '@angular/core';
import jsPDF, { TextOptionsLight } from 'jspdf';
import { fontBolt, fontRegular } from './reporting-constants';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root',
})
export class ReportingCommonService {
  fontNameBold = 'Montserrat-Bold';
  fontNameNormal = 'Montserrat-Regular';

  initializeFonts(doc: jsPDF): void {
    doc.addFileToVFS('Montserrat-Bold-bold.ttf', fontBolt);
    doc.addFont('Montserrat-Bold-bold.ttf', 'Montserrat-Bold', 'bold');

    doc.addFileToVFS('Montserrat-Regular-normal.ttf', fontRegular);
    doc.addFont('Montserrat-Regular-normal.ttf', 'Montserrat-Regular', 'normal');
  }

  setBoldFont(doc: jsPDF): void {
    doc.setFont(this.fontNameBold, 'bold');
  }

  setNormalFont(doc: jsPDF): void {
    doc.setFont(this.fontNameNormal, 'normal');
  }

  drawTable(
    doc: jsPDF,
    {
      x = 0,
      y = 0,
      title1 = '',
      title2 = '',
      indicators = [], // [{name : "", value: ""}]
      lineHeight = 16,
      data = [],
      legends = [],
      column1Width = 100,
      display = (val: string) => ('' + val).replace(/,/g, '.'),
      marginRight = undefined,
      textFontSize = 8,
      rowDrawListener = () => true,
      colorTableCell = () => [255, 255, 255],
    }: {
      x?: number;
      y?: number;
      title1?: string;
      title2?: string;
      indicators?: { name: string; value: string }[];
      lineHeight?: number;
      data?: any[][];
      legends?: string[];
      column1Width?: number;
      display?: (val: any) => string;
      marginRight?: number | undefined;
      textFontSize?: number;
      rowDrawListener?: (index: number, y: number) => boolean;
      colorTableCell?: (cellValue: any, rowNumber: number) => number[];
    } = {},
    emptyBlock = false
  ): void {
    const X = x !== 0 ? x : 50;
    const marginR = marginRight != null ? marginRight : 80;
    // draw the title
    doc.setFontSize(12);
    this.setBoldFont(doc);
    doc.text(title1, x, y);
    const textWidth = doc.getTextWidth(title1);

    // draw second title
    this.setNormalFont(doc);
    doc.setFontSize(10);
    doc.text(title2, x + textWidth + 4, y);

    if (data.length === 0 || emptyBlock) {
      return;
    }

    // draw each indicator from right to left
    let Y = y - 12;
    this.drawIndicator(doc, doc.internal.pageSize.width - 50, Y, indicators);

    this.setNormalFont(doc);
    doc.setFontSize(7);
    // draw the table
    Y = Y + 20;

    // draw borders
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.6);

    // horizontal lines
    let horizontalY = Y;

    // vertical lines
    let horizontalXLine = X;
    let horizontalYLine = Y;
    const width = doc.internal.pageSize.width - (X + marginR) - column1Width;
    const xSpacing = width / (data[0].length - 1);
    let horizontalX = X + 5;

    for (let i = 0; i < data.length; i++) {
      if (!rowDrawListener(i, horizontalY)) {
        break;
      }
      this.setBoldFont(doc);
      doc.text(data[i][0], horizontalX, horizontalY + lineHeight / 2 + 2);
      horizontalY += lineHeight;
    }

    horizontalX = X + column1Width;
    horizontalY = Y;

    for (let i = 0; i < data.length; i++) {
      if (!rowDrawListener(i, horizontalY)) {
        break;
      }
      for (let j = 1; j < data[i].length; j++) {
        doc.setFontSize(textFontSize);
        this.setNormalFont(doc);
        if (i < 1) {
          this.setBoldFont(doc);
        }

        const boxContent = data[i][j];

        const color = colorTableCell(boxContent, i);
        doc.setFillColor(color[0], color[1], color[2]);
        doc.rect(horizontalX, horizontalY, xSpacing, lineHeight, 'F');

        doc.text(display(boxContent), horizontalX + xSpacing - 2, horizontalY + lineHeight / 2 + 2, { align: 'right' });
        horizontalX += xSpacing;
      }
      horizontalY = horizontalY + lineHeight;
      horizontalX = X + column1Width;
    }

    // draw the horizontal lines
    let lastI = data.length;
    for (let i = 0; i <= data.length; i++) {
      if (!rowDrawListener(i, horizontalYLine - lineHeight)) {
        lastI = i - 1;
        break;
      }
      doc.line(X, horizontalYLine, doc.internal.pageSize.width - marginR, horizontalYLine);
      horizontalYLine += lineHeight;
    }

    // draw the vertical lines
    horizontalXLine = X;
    horizontalYLine = Y;
    for (let i = 0; i <= data[0].length; i++) {
      doc.line(horizontalXLine, horizontalYLine, horizontalXLine, horizontalYLine + lastI * lineHeight);
      horizontalXLine += i === 0 ? column1Width : xSpacing;
    }

    // add the legends to the right side of the table
    const legendX = doc.internal.pageSize.width - 70;
    const legendY = Y;
    doc.setFontSize(8);
    this.addVerticalTexts(doc, legends.splice(0, lastI), legendX + 20, legendY, lineHeight, { align: 'right' });
  }

  drawIndicator(doc: jsPDF, x: number, Y: number, indicators: string | any[]): void {
    for (let i = indicators.length - 1; i >= 0; i--) {
      const indicator = indicators[i];
      this.setNormalFont(doc);
      doc.setFontSize(10);
      let textWidth = doc.getTextWidth(indicator.value); // ??
      doc.text(indicator.value, x, Y + 10, { align: 'right' });
      x = x - 8 - textWidth;
      this.setBoldFont(doc);
      doc.setFontSize(10);

      textWidth = doc.getTextWidth(indicator.name);
      doc.text(indicator.name, x - textWidth, Y + 10);
      x = x - 15 - textWidth;
    }
  }

  addVerticalTexts(
    doc: jsPDF,
    texts: string | any[],
    x: number,
    y: number,
    lineHeight: number,
    justify?: TextOptionsLight | undefined
  ): void {
    if (justify === undefined) {
      justify = { align: 'left' };
    }

    for (let i = 0; i < texts.length; i++) {
      doc.text(texts[i], x, y + lineHeight * i + (lineHeight * 3) / 4, justify);
    }
  }

  addDeltaChart(
    doc: jsPDF,
    data: number[] = [],
    x = 0,
    y = 0,
    width = 1,
    height = 450,
    fillColor: [number, number, number] = [100, 130, 154],
    addTicks = true,
    tableTitle = '',
    xLabel = 'Delta, #',
    xTicks: string[] = []
  ): void {
    if (data.length === 0) {
      return;
    }

    const margin = { left: 0, right: 0, top: 0, bottom: 0 };
    const barWidth = width / (data.length - 1); // Width of the bars
    const chartWidth = margin.left + data.length * barWidth + margin.right;
    /* This scale produces negative output for negative input */

    const maxData = d3.max(data) !== undefined ? (d3.max(data) as number) : 0;
    const minData = d3.min(data) !== undefined ? (d3.min(data) as number) : 0;
    const yScale = d3.scaleLinear().domain([0, maxData]).range([0, height]);

    /*
     * We need a different scale for drawing the y-axis. It needs
     * a reversed range, and a larger domain to accommodate negative values.
     */

    const yAxisScale = d3
      .scaleLinear()
      .domain([minData, maxData])
      .range([height - yScale(minData), 0]);

    const xScale = d3
      .scaleBand()
      .domain(data.map((value, i) => String(i)))
      .range([0, width]);

    const svg = d3.select('body').append('svg');
    svg
      .attr('height', height + 100)
      .attr('width', chartWidth)
      .style('border', '1px solid');

    doc.setDrawColor(66, 66, 66);
    doc.line(x, y + height - yScale(0), x + width, y + height - yScale(0));

    const yAxis = d3.axisLeft(yAxisScale);

    const yTicks = svg
      .append('g')
      .attr('transform', () => `translate(${margin.left}, 0)`)
      .call(yAxis)
      .selectAll('text');
    const ticksData = yTicks.data();
    ticksData.forEach(d => {
      if (d === 0) {
        return;
      }

      const d2 = d as number;

      doc.setDrawColor(204, 204, 204);
      doc.setLineWidth(0.5);
      doc.line(x, y + height - yScale(d2), x + width, y + height - yScale(d2));
      doc.setFontSize(6);
      this.setNormalFont(doc);
      doc.text(d2.toString(), x - 3, y + height - yScale(d2), { align: 'right' });
    });

    doc.setFontSize(8);
    this.setBoldFont(doc);
    doc.text(xLabel, x - 30, y + height, { angle: 90 }, 90);

    data.forEach((d2, i) => {
      const X = xScale(i.toString());
      const Y = height - Math.max(0, yScale(d2));
      const h = Math.abs(yScale(d2));
      doc.setFillColor(...fillColor);
      if (d2 !== 0) {
        if (X) {
          doc.roundedRect(x + X + 1, Y + y, xScale.bandwidth() - 2, h, 1, 1, 'F');
        } else {
          doc.roundedRect(x + 1, Y + y, xScale.bandwidth() - 2, h, 1, 1, 'F');
        }
      }

      if (addTicks) {
        this.setNormalFont(doc);
        doc.setFontSize(5);
        if (X) {
          if (xTicks.length > 0) {
            doc.text(xTicks[i], x + X + (barWidth * 2) / 5, y + height + 8, { align: 'center' });
          }
        } else {
          if (xTicks.length > 0) {
            doc.text(xTicks[i], x + (barWidth * 2) / 5, y + height + 8, { align: 'center' });
          }
        }
      }
    });

    this.setBoldFont(doc);
    doc.setFontSize(12);
    doc.text(tableTitle, x + width / 2, y - 18, { align: 'center' });

    svg.remove();
  }

  drawAreaChart(
    doc: jsPDF,
    data: number[],
    x = 0,
    y = 0,
    width = 1,
    height = 450,
    maxYParam = 160,
    drawColor: [number, number, number] = [100, 130, 154],
    fillColor: [number, number, number] = [100, 130, 154],
    enableTicks = true,
    days = 30
  ): void {
    const margin = { left: 0, right: 0, top: 0, bottom: 0 };
    const barWidth = width / (data.length - 1); // Width of the bars
    const chartWidth = margin.left + data.length * barWidth + margin.right;

    const maxData = d3.max(data) !== undefined ? (d3.max(data) as number) : 0;
    const maxY = d3.max([maxData, maxYParam]) !== undefined ? (d3.max([maxData, maxYParam]) as number) : 0;

    /* This scale produces negative output for negative input */
    const yScale = d3.scaleLinear().domain([0, maxY]).range([0, height]);

    /*
     * We need a different scale for drawing the y-axis. It needs
     * a reversed range, and a larger domain to accommodate negative values.
     */
    const minData = d3.min(data) !== undefined ? (d3.min(data) as number) : 0;
    const yAxisScale = d3
      .scaleLinear()
      .domain([0, maxY])
      .range([height - yScale(minData), 0]);

    const xScale = d3
      .scaleBand()
      .domain(data.map((value, i) => String(i)))
      .range([0, width]);

    const svg = d3.select('body').append('svg');
    svg
      .attr('height', height + 100)
      .attr('width', chartWidth)
      .style('border', '1px solid');

    doc.setDrawColor(66, 66, 66);
    doc.line(x, y + height - yScale(0), x + width, y + height - yScale(0));

    const yAxis = d3.axisLeft(yAxisScale);
    if (enableTicks) {
      const yTicks = svg
        .append('g')
        .attr('transform', function () {
          return `translate(${margin.left}, 0)`;
        })
        .call(yAxis)
        .selectAll('text');
      const ticksData = yTicks.data();
      ticksData.forEach(d => {
        const d2 = d as number;
        doc.setDrawColor(204, 204, 204);
        doc.setLineWidth(0.5);
        doc.line(x, y + height - yScale(d2), x + width, y + height - yScale(d2));
        doc.setFontSize(6);
        this.setNormalFont(doc);
        doc.text(`${d2}`, x - 3, y + height - yScale(d2), { align: 'right' });
      });

      const d = new Date();
      d.setDate(d.getDate() - days);
      const xScaling = d3.scaleTime().domain([d, new Date()]).range([0, width]);

      const x_values = d3.utcDays(d, new Date(), days / 10);
      x_values.forEach(v => {
        const date = new Date(v);
        doc.text(`${date.getDate()}.${date.getMonth() + 1}`, x + xScaling(v), y + height + 10);
      });
    }
    const lines = [[x, y + height - yScale(0)]];
    data.forEach((d, i) => {
      const X = xScale(i.toString());
      const Y = height - Math.max(0, yScale(d));
      // if (d !== 0) {
      lines.push([X !== undefined ? x + X : x, Y + y]);
      lines.push([X !== undefined ? x + X : x + xScale.bandwidth(), Y + y]);
      // }
    });
    lines.push([lines[lines.length - 1][0], y + height - yScale(0)]);
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...drawColor);
    doc.setLineWidth(1);

    this.polygon(lines, [1.0, 1.0], 'FD', true, doc);

    svg.remove();
  }

  drawSpatialTable(doc: jsPDF, x = 0, y = 0, width = 1, height = 1, data: number[][] = [[]]): void {
    const rows = data.length;
    const rowHeight = height / rows;
    const columnWidth = width / (data[0].length - 1);
    doc.setFontSize(8);

    for (let i = 0; i < rows; i++) {
      const rowData = data[i];
      const newY = y + i * rowHeight;
      rowData.forEach((col, index) => {
        let newX = x + index * columnWidth;
        let justify: TextOptionsLight = { align: 'left' };
        if (index > 4) {
          newX -= 14;
        }

        if (index === 0) {
          justify = { align: 'left' };
          const [boldText, lightText] = col.toString().split(' ');
          this.setBoldFont(doc);
          doc.text(boldText, newX, newY, justify);
          newX += doc.getTextWidth(boldText) + 3;
          this.setNormalFont(doc);
          if (lightText) {
            doc.text(lightText, newX, newY, justify);
          }
          return;
        } else if (index === 4) {
          justify = { align: 'center' };
          if (i === 0) {
            doc.setFontSize(6);
          }
        }
        doc.text(col.toString(), newX, newY, justify);
        doc.setFontSize(8);
      });
    }
  }

  polygon(points: number[][], scale: number[], style: string, closed: boolean, doc: jsPDF): void {
    const x1 = points[0][0];
    const y1 = points[0][1];
    let cx = x1;
    let cy = y1;
    const acc = [];
    for (let i = 1; i < points.length; i++) {
      const point = points[i];
      const dx = point[0] - cx;
      const dy = point[1] - cy;
      acc.push([dx, dy]);
      cx += dx;
      cy += dy;
    }
    doc.lines(acc, x1, y1, scale, style, closed);
  }

  Calendar2(
    data: any,
    doc: jsPDF,
    formatMonth = '%b', // format specifier string for months (above the chart)
    {
      x = (dates: { date: string; value: number }) => new Date(dates.date),
      y = (m: { date: string; value: number }) => m.value,
      width = 928, // width of the chart, in pixels
      cellSize = 17, // width and height of an individual day, in pixels
      weekday = 'monday', // either: weekday, sunday, or monday
      formatDay = i => 'SMTWTFS'[i], // given a day number in [0, 6], the day-of-week label
      // colors = undefined, // d3.interpolatePiYG,
      xPosition = 0,
      yPosition = 0,
      fontSize = 8,
      useHexConverter = false,
      colorScale = undefined,
    }: {
      x?: (val: any) => Date;
      y?: (val: any) => number;
      width?: number;
      cellSize?: number;
      weekday?: string;
      formatDay?: (n: number) => string;
      colors?: any;
      xPosition?: number;
      yPosition?: number;
      fontSize?: number;
      useHexConverter?: boolean;
      colorScale?: any;
    } = {}
  ): void {
    doc.setFontSize(fontSize);

    // function getPathData(path: SVGPathElement): number[][]  {
    //     const NUM_POINTS = 4;
    //     const len = path.getTotalLength();
    //     const points = [];

    //     for (let i = 0; i < NUM_POINTS; i++) {
    //         const pt = path.getPointAtLength(i * len / (NUM_POINTS - 1));
    //         points.push([pt.x, pt.y]);
    //     }
    //     return points
    // }

    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);
    const I = d3.range(X.length);

    const countDay = weekday === 'sunday' ? (i: number) => i : (i: number) => (i + 6) % 7;
    const timeWeek = weekday === 'sunday' ? d3.utcSunday : d3.utcMonday;
    const weekDays = weekday === 'weekday' ? 5 : 7;
    const height = cellSize * (weekDays + 2);

    const color = colorScale; // || d3.scaleSequential([1, 5], colors);

    // Construct formats.
    const formatMonthArrayFunction = d3.utcFormat(formatMonth);

    // Compute titles.
    // if (title === undefined) {
    //     const formatDate = d3.utcFormat("%B %-d, %Y");
    //     const formatValue = color.tickFormat(100, yFormat);
    //     title = i => `${formatDate(X[i])}\n${formatValue(Y[i])}`;
    // } else if (title !== null) {
    //     const T = d3.map(data, title);
    //     title = i => T[i];
    // }

    // Group the index by year, in reverse input order. (Assuming that the input is
    // chronological, this will show years in reverse chronological order.)
    const years = d3.groups(I, i => X[i].getUTCFullYear()).reverse();

    // function pathMonth(t: Date): string {
    //     const d = Math.max(0, Math.min(weekDays, countDay(t.getUTCDay())));
    //     const w = timeWeek.count(d3.utcYear(t), t);
    //     return `${d === 0 ? `M${w * cellSize},0`
    //         : d === weekDays ? `M${(w + 1) * cellSize},0`
    //             : `M${(w + 1) * cellSize},0V${d * cellSize}H${w * cellSize}`}V${weekDays * cellSize}`;
    // }

    const svg = d3
      .create('svg')
      .attr('width', width)
      .attr('height', height * years.length)
      .attr('viewBox', [0, 0, width * 1.5, height * years.length].join(' '))
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 14);

    const year = svg
      .selectAll('g')
      .data(years)
      .join('g')
      .attr('transform', (d, i) => `translate(40.5,${height * i + cellSize * 1.5})`);

    doc.text(String(years[0][0]), xPosition - 5, yPosition - 5);

    const daysData = year
      .append('g')
      .attr('text-anchor', 'end')
      .selectAll('text')
      .data(weekday === 'weekday' ? d3.range(1, 6) : d3.range(7))
      .join('text')
      .data()
      .map(d => ({
        day: formatDay(d),
        x: xPosition + 5,
        y: yPosition + (countDay(d) + 0.5) * cellSize,
      }));

    daysData.forEach(d => {
      doc.text(d.day, d.x, d.y);
    });

    const cellData = year
      .append('g')
      .selectAll('rect')
      .data(weekday === 'weekday' ? ([, indices]) => indices.filter(i => ![0, 6].includes(X[i].getUTCDay())) : ([, indices]) => indices)
      .join('rect')
      .data()
      .map(d => {
        function hexToRgb(hex: string): string {
          const bigint = parseInt(hex, 16);
          const r = Math.floor(bigint / 65536) % 256;
          const g = Math.floor(bigint / 256) % 256;
          const b = Math.floor(bigint) % 256;

          return r.toString() + ',' + g.toString() + ',' + b.toString();
        }

        const colorVal = useHexConverter ? hexToRgb(color(Y[d]).replace('#', '')) : color(Y[d]);

        return {
          width: cellSize - 1,
          height: cellSize - 1,
          x: xPosition + cellSize + timeWeek.count(d3.utcYear(X[d]), X[d]) * cellSize + 0.5,
          y: yPosition + countDay(X[d].getUTCDay()) * cellSize + 0.5,
          color: colorVal
            .replace('rgb(', '')
            .replace(')', '')
            .split(',')
            .map((e: number) => +e),
        };
      });
    cellData.forEach((d: { x: number; y: number; width: number; height: number; color: [number, number, number] }) => {
      doc.setDrawColor(...d.color);
      doc.setFillColor(...d.color);
      doc.rect(d.x, d.y, d.width, d.height, 'F');
    });

    const month = year
      .append('g')
      .selectAll('g')
      .data(([, indexes]) => d3.utcMonths(d3.utcMonth(X[indexes[0]]), X[indexes[indexes.length - 1]]))
      .join('g');

    // const monthPathData = month.filter((d, i) => i !== 0 ).append("path")
    //     .attr("fill", "none")
    //     .attr("stroke", "#fff")
    //     .attr("stroke-width", 3)
    //     .data().map(d => {
    //         const pathMon = pathMonth(d)
    //         const [part1, part2] = pathMon.split(",0V")

    //         const path = svg.append('path').attr('d', pathMonth(d))
    //         const pathNode = path.node();
    //         const datas = pathNode ? getPathData(pathNode) : null;
    //         return {
    //             data: datas
    //         }
    //     })

    const monthsTextData = month
      .append('text')
      .data()
      .map(d => ({
        x: xPosition + timeWeek.count(d3.utcYear(d), timeWeek.ceil(d)) * cellSize + 5,
        y: yPosition - 5,
        text: formatMonthArrayFunction(d),
      }));

    monthsTextData.forEach(d => {
      doc.text(d.text, d.x, d.y);
    });

    svg.remove();
  }
}
