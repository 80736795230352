import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportingCommonService } from './reporting-common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingPage1Service {
  constructor(private reportingCommonService: ReportingCommonService) {}

  generateReport(doc: jsPDF, data: any): number {
    const { column1, column2, column3, column4 } = data;

    doc.setPage(1);
    let y = 65;
    const x = 30;

    // add title to top left corner of the page
    this.reportingCommonService.setBoldFont(doc);
    doc.setFontSize(14);
    doc.text('Persönliche Daten', x + 6, y);
    this.reportingCommonService.setNormalFont(doc);
    doc.setFontSize(11);

    let valueWidth = 150;

    // for column 1
    let keys = Object.keys(column1);
    let Y = y + 25;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = column1[key];
      value = doc.splitTextToSize(value, valueWidth);
      doc.text(key, x + 6, Y);
      doc.text(value, x + 102, Y);
      Y += 18 + 18 * (value.length - 1);
    }

    let maxY = Y;

    valueWidth = 210;
    // for column 2
    keys = Object.keys(column2);
    Y = y + 25;
    let X = doc.internal.pageSize.width / 3 - 60;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = column2[key];
      value = doc.splitTextToSize(value, valueWidth);
      doc.text(key, X, Y);
      doc.text(value, X + 70, Y);
      Y += 18 + 18 * (value.length - 1);
    }
    maxY = Math.max(Y, maxY);

    // for column 3
    keys = Object.keys(column3);
    Y = y + 25;
    X = (doc.internal.pageSize.width / 3) * 2 - 125;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = column3[key];
      doc.text(key, X, Y);
      doc.text(value, X + 100, Y);
      Y += 18;
      if (i === 0) {
        for (let j = 0; j < value.length; j++) {
          Y += 10;
        }
      }
    }

    maxY = Math.max(Y, maxY);

    // add a rectangle
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    doc.rect(x, y - 20, doc.internal.pageSize.width - x * 2, maxY - y + 20);

    y = y + 185 + 21;

    // add a rectangle
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    doc.rect(x, y - 20, doc.internal.pageSize.width - x * 2, 300);

    this.reportingCommonService.setBoldFont(doc);
    doc.setFontSize(14);
    doc.text('Hintergrund / Notfall-Datenmanagement (NFDM)', x + 6, y);
    this.reportingCommonService.setNormalFont(doc);
    doc.setFontSize(11);

    // for column 4
    keys = Object.keys(column4);
    Y = y + 25;
    X = x;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = column4[key];
      doc.text(key, X + 8, Y);
      value = doc.splitTextToSize(value, doc.internal.pageSize.width - X - 250);
      doc.text(value, X + 200, Y);
      Y += 32;
      if (i === 3) {
        for (let j = 0; j < value.length; j++) {
          Y += 10;
        }
      }
    }
    return 1;
  }
}
