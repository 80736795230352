import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportingCommonService } from './reporting-common.service';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root',
})
export class ReportingPage3Service {
  constructor(private reportingCommonService: ReportingCommonService) {}

  render(doc: jsPDF, data: any, nbPage: number): number {
    const { calendar } = data;
    calendar.forEach(function (row: any) {
      if (row.value > 6) {
        row.value = row.value - 5;
      }
    });

    doc.setPage(nbPage);

    // add a rectangle
    const x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    // doc.rect(x, 50, doc.internal.pageSize.width - x * 2, doc.internal.pageSize.height - 102, 'FD');
    doc.rect(x, 50, doc.internal.pageSize.width - x * 2, doc.internal.pageSize.height - 92, 'FD');

    // draw bold title
    this.reportingCommonService.setBoldFont(doc);
    let title = 'Subjektives Wohlbefinden';
    doc.setFontSize(14);
    doc.text(title, 42, 70);

    this.reportingCommonService.drawTable(
      doc,
      {
        x: 42,
        y: 90,
        title1: 'Wohlbefinden und Stresslevel,',
        title2: 'letzte 30 Tage',
        indicators: [
          { name: 'Ziel', value: '1' },
          { name: 'Max', value: data.table1.last30DayWellBeingIndexMax },
          { name: 'Min', value: data.table1.last30DayWellBeingIndexMin },
          { name: 'Durchschnitt', value: data.table1.last30DayWellBeingIndexAvg },
          { name: 'Std.Abw.', value: data.table1.last30DayWellBeingIndexStdDev },
        ],
        data: data.table1.data,
        legends: data.table1.legends,
        // width: doc.internal.pageSize.width - x * 2,
        display(val: string) {
          if (val === 'N' || val === 'J') {
            return '';
          }
          return val;
        },
        marginRight: 50,
        colorTableCell(cellValue: string, rowNumber: number) {
          let color = [255, 255, 255]; // default
          if (rowNumber !== 0) {
            if (cellValue.includes('N')) {
              color = [232, 162, 177]; // red if less "N"
            } else if (cellValue.includes('J')) {
              color = [173, 199, 147]; // red if less "J"
            }
          }
          return color;
        },
      },
      data.table1.emptyWellBeingBlock
    );

    this.reportingCommonService.drawTable(
      doc,
      {
        x: 42,
        y: 254,
        title1: 'Herzinsuffizienz Indikator,',
        title2: 'letzte 30 Tage',
        // width: doc.internal.pageSize.width - x * 2,
        indicators: [],
        data: data.table2.data,
        colorTableCell(cellValue: string, rowNumber: number) {
          let color = [255, 255, 255]; // default
          if (rowNumber > 0 && rowNumber < 5) {
            if (cellValue.includes('N')) {
              color = [232, 162, 177]; // red if less "N"
            } else if (cellValue.includes('J')) {
              color = [173, 199, 147]; // red if less "J"
            }
          } else if (
            (rowNumber === 5 && cellValue >= '2') || // Nykturi: => 2
            (rowNumber === 6 && cellValue >= '1') || // 24h: >=1 Kg
            (rowNumber === 7 && cellValue >= '2') || // 72h: >= 2kg
            (rowNumber === 8 && cellValue >= '2.5') // 7day: >=2,5kg
          ) {
            color = [232, 162, 177]; // red if 24h: >=1 Kg
          }

          return color;
        },
        display(val: string) {
          if (val === 'N') {
            return 'ja   ';
          }
          if (val === 'J') {
            return '';
          }
          return val;
        },
        column1Width: 110,
        legends: data.table1.legends,
        marginRight: 50,
      },
      data.table2.emptyWellBeingHeartFailureBlock
    );

    // add title to the top of the page
    this.reportingCommonService.setBoldFont(doc);
    doc.setFontSize(14);
    title = 'Wohlbefinden auf der Skala 1-5,';
    doc.text(title, 42, 425);

    // title width
    const titleWidth = doc.getTextWidth(title);
    this.reportingCommonService.setNormalFont(doc);
    doc.setFontSize(12);
    doc.text('letzte 12 Monate', 42 + titleWidth + 3, 425);

    const colors = ['#ddc4e0', '#b893bd', '#8f6695', '#623968', '#38183d', '#F8F8F8'];
    this.reportingCommonService.Calendar2(calendar, doc, '%b', {
      x: (m: { date: string; value: number }) => new Date(m.date),
      y: (m: { date: string; value: number }) => m.value,
      cellSize: 15,
      xPosition: 48,
      // yPosition: 430,
      yPosition: 445,
      useHexConverter: true,
      colorScale: d3.scaleOrdinal<number, string>().domain([1, 2, 3, 4, 5]).range(colors),
    });

    return nbPage;
  }
}
