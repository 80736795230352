import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { includesAny, parseJwt } from 'app/modules/shared/models/common';

const ROLES = [
    'ROLE_ADMIN',
    'ROLE_CONTACT',
    'ROLE_JUH_SYSTEM',
    'ROLE_SUPERVISOR',
    'ROLE_USER',
    'ROLE_SSO_ADMIN',
];

export const RoleGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const authService = inject(AuthService);
    const decode = parseJwt(authService.accessToken);
    let roles = decode.roles || [];

    // if (decode?.auth) {
    //     roles = decode.auth.split(',');
    // }

    console.log(roles);

    const allowedRoles = route.data.roles as Array<string>;

    if (includesAny(roles, allowedRoles)) {
        return true;
    } else {
        // Redirect or handle unauthorized access
        return false;
    }
};
