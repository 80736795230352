import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlShortenedService } from 'app/core/api/url-shortened.service';
import { UrlShortened } from 'app/modules/shared/models/url-shortend.model';
import { EmergencyAgentAccessService } from '../overview/emergency/emergency-agent-access/emergency-agent-access.service';
import { WifiSettingsService } from 'app/core/api/wifi-settings.service';

@Component({
    selector: 'url-shortened',
    templateUrl: './url-shortened.component.html',
    styleUrls: ['./url-shortened.component.scss'],
})
export class UrlShortenedEaaComponent implements OnInit {
    code = '';
    isLoading = true;
    urlShortened = new UrlShortened();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private urlShortenedService: UrlShortenedService,
        private emergencyAgentAccessService: EmergencyAgentAccessService,
        private wifiSettings: WifiSettingsService
    ) {}

    ngOnInit(): void {
        this.code = this.route.snapshot.params['code'];

        this.urlShortenedService.findByCode(this.code).subscribe((received) => {
            this.isLoading = false;
            this.urlShortened = received;
            if (this.urlShortened.urlOriginal) {
                // eaa
                if (this.urlShortened.urlOriginal.includes('eaa')) {
                    const match =
                        this.urlShortened.urlOriginal.match(/[?&]eaa=([^&]+)/);
                    const eaaToken = match
                        ? decodeURIComponent(match[1])
                        : null;
                    if (eaaToken) {
                        this.emergencyAgentAccessService.setEaaTokenSubject(
                            eaaToken
                        );
                        const randomValue = Math.random()
                            .toString(36)
                            .substring(6);
                        this.router.navigate(
                            ['emergency-center-agent-access'],
                            { queryParams: { eaa: eaaToken, v: randomValue } }
                        );
                    }
                }
                // wst
                if (this.urlShortened.urlOriginal.includes('wst')) {
                    console.log(this.urlShortened.urlOriginal);

                    const match =
                        this.urlShortened.urlOriginal.match(/[?&]wst=([^&]+)/);
                    const wstToken = match
                        ? decodeURIComponent(match[1])
                        : null;
                    if (wstToken) {
                        this.wifiSettings
                            .isValidWifiSettingsToken(wstToken)
                            .subscribe(
                                (res) => {
                                    if (res?.valid) {
                                        console.log(res);
                                        // const randomValue = Math.random().toString(36).substring(6);
                                        // this.router.navigate(['wifi-settings'], { queryParams: { wst: wstToken, v: randomValue } });
                                        if (
                                            window.location.pathname.includes(
                                                'http://localhost:4200'
                                            )
                                        ) {
                                            this.urlShortened.urlOriginal =
                                                this.urlShortened.urlOriginal.replace(
                                                    'https://adele.test.almaphil.de',
                                                    'http://localhost:4200'
                                                );
                                        }
                                        window.location.href =
                                            this.urlShortened.urlOriginal;
                                    } else {
                                        window.location.href = '/';
                                    }
                                },
                                (err) => {
                                    window.location.href = '/';
                                }
                            );
                    }
                }
            }
        });
    }
}
