import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportingCommonService } from './reporting-common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingPage6Service {
  constructor(private reportingCommonService: ReportingCommonService) {}

  render(doc: jsPDF, data: any, nbPage: number): number {
    doc.setPage(nbPage);

    let startX = 42;
    const texts = ['Blutdruck und Puls', ' letzte ', data.lastConfigurableBPDays, ' Tage'];
    doc.setFontSize(14);
    texts.forEach((text, index) => {
      this.reportingCommonService.setNormalFont(doc);
      if (index % 2 === 0) {
        this.reportingCommonService.setBoldFont(doc);
      }
      doc.text(text, startX, 75);
      startX += doc.getTextWidth(text);
    });

    // add a rectangle
    let x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    doc.rect(x, 50, doc.internal.pageSize.width - x * 2, doc.internal.pageSize.height - 95);

    // add the chart left titles
    x = 42;
    let titleY = 155;
    let title = 'Systolisch';
    doc.setFontSize(10);
    this.reportingCommonService.setBoldFont(doc);
    doc.text(title, x, titleY);

    titleY = 245;
    title = 'Diastolisch';
    doc.text(title, x, titleY);

    titleY = 295;
    title = 'Puls';
    doc.text(title, x, titleY);

    const chartPaddingLeft = 90;
    this.reportingCommonService.drawAreaChart(
      doc,
      data.dataXDaysSystolic,
      x + chartPaddingLeft, // x
      100, // y
      doc.internal.pageSize.width - x * 2 - chartPaddingLeft - 10, // width
      205, // height
      160, // maxY
      [100, 130, 154], // drawColor
      [174, 198, 228], // fillColor
      true,
      data.lastConfigurableBPDays as number
    );

    this.reportingCommonService.drawAreaChart(
      doc,
      data.dataXDaysDiastolic,
      x + chartPaddingLeft,
      100,
      doc.internal.pageSize.width - x * 2 - chartPaddingLeft - 10,
      205,
      160,
      [78, 114, 98],
      [180, 212, 191],
      false,
      data.lastConfigurableBPDays as number
    );

    this.reportingCommonService.drawAreaChart(
      doc,
      data.dataXDaysPulse,
      x + chartPaddingLeft,
      100,
      doc.internal.pageSize.width - x * 2 - chartPaddingLeft - 10,
      205,
      160,
      [190, 2, 114],
      [190, 2, 114],
      false,
      data.lastConfigurableBPDays as number
    );

    // bar chart
    const tableX = 30;
    const chartWidth = 140;
    let xPosition = tableX + 100;
    const xPadding = 40;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.valuesXDaySystolicDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [174, 198, 228],
      true,
      'Systolisch',
      '',
      data.xTicksXDaySystolicDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.valuesXDayDiastolicDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [180, 212, 191],
      true,
      'Diastolisch',
      '',
      data.xTicksXDayDiastolicDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.valuesXDayPulseDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [190, 2, 114],
      true,
      'Puls',
      '',
      data.xTicksXDayPulseDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.valuesXDay24HoursPulseDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [190, 2, 114],
      true,
      '24h-Puls',
      '',
      data.xTicksXDay24HoursPulseDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.valuesXDayDruckDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [190, 2, 114],
      true,
      'Pulsdruck',
      '',
      data.xTicksXDayDruckDeltaChart
    );

    const tableHeight = 80;
    this.reportingCommonService.drawSpatialTable(
      doc,
      x,
      doc.internal.pageSize.height - tableHeight - 45,
      doc.internal.pageSize.width - 2 * x - 105,
      tableHeight,
      data.table
    );

    return nbPage;
  }
}
