import { TextFieldModule } from '@angular/cdk/text-field';
import {
    NgIf,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    DatePipe,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule } from '@ngneat/transloco';
import { Chat } from 'app/layout/common/quick-chat/quick-chat.types';
import { Subject, takeUntil } from 'rxjs';
import { EmergencyAgentAccessService } from '../emergency-agent-access.service';
import { IGroupChatMessage } from 'app/modules/shared/models/group-chat-message.model';

@Component({
    selector: 'group-chat',
    standalone: true,
    templateUrl: './group-chat.component.html',
    styleUrl: './group-chat.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslocoModule,
        NgIf,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        NgFor,
        NgClass,
        NgTemplateOutlet,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        DatePipe,
    ],
})
export class GroupChatComponent {
    @Input() chatMessages = [];
    @Input() drawer: MatDrawer;

    @Output() sendMessageEvent = new EventEmitter();

    @ViewChild('messageInput') messageInput: ElementRef;
    @ViewChild('chatscroll') chatscroll: ElementRef;

    // chat: Chat;
    groupChatMessages: IGroupChatMessage[] = [];
    // chatMessagesTem = [];
    messages = [];

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = false;
    messageCount = 10;
    isLoading = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private emergencyAgentAccessService: EmergencyAgentAccessService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Decorated methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resize on 'input' and 'ngModelChange' events
     *
     * @private
     */
    // @HostListener('input')
    // @HostListener('ngModelChange')
    // private _resizeMessageInput(): void {
    //     // This doesn't need to trigger Angular's change detection by itself
    //     this._ngZone.runOutsideAngular(() => {
    //         setTimeout(() => {
    //             // Set the height to 'auto' so we can correctly read the scrollHeight
    //             this.messageInput.nativeElement.style.height = 'auto';

    //             // Detect the changes so the height is applied
    //             this._changeDetectorRef.detectChanges();

    //             // Get the scrollHeight and subtract the vertical padding
    //             this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;

    //             // Detect the changes one more time to apply the final height
    //             this._changeDetectorRef.detectChanges();
    //         });
    //     });
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.emergencyAgentAccessService.groupChatMessages$.subscribe(
            (data: IGroupChatMessage[]) => {
                this.groupChatMessages = [];
                this.messages = [];
                this.isLoading = false;
                // this.groupChatMessages = res.reverse() ?? [];
                this.groupChatMessages = data ?? [];
                this.addNewMessages();
                setTimeout(() => {
                    this.chatscroll.nativeElement.scrollTop =
                        this.chatscroll.nativeElement.scrollHeight;
                }, 100);

                // this.scrollToBottom();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            },
            () => {
                this.isLoading = false;
            }
        );

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                } else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.groupChatMessages = [];
        this.messages = [];
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    addNewMessages() {
        const newMessages = this.groupChatMessages.splice(0, 50).reverse();
        // const newMessages = this.chatMessagesTem.splice(0, 50);
        this.messages = [...newMessages, ...this.messages];
        console.log(this.messages.length, this.groupChatMessages.length);
    }

    onScroll($event) {
        if (!this.groupChatMessages.length) {
            return;
        }

        const elem: HTMLElement = $event.srcElement;

        if (elem.scrollTop < 1) {
            elem.scrollTo(0, 1);
        }

        if (this.isLoading) {
            return;
        }

        if (elem.scrollTop < 50) {
            this.isLoading = true;
            setTimeout(() => {
                const chatLength =
                    this.groupChatMessages.length >= 50
                        ? 50
                        : this.groupChatMessages.length;
                this.addNewMessages();
                this.isLoading = false;

                this.chatscroll.nativeElement.scrollTop = chatLength * 60;
            }, 2000);
        }
    }

    sendMessage() {
        const message = this.messageInput?.nativeElement?.value;
        if (message) {
            this.sendMessageEvent.next(message);
        }
        // console.log(message);
        // const gcMessage = new GroupChatMessageDTO();
        // gcMessage.messageText = message;
        // gcMessage.groupChatId = this.groupChat.id;

        // this._chatService.sendGroupMessage(gcMessage).subscribe((message) => {
        console.log(message);
        this.messages.push(message);
        console.log(this.messageInput.nativeElement);
        this.messageInput.nativeElement.value = '';
        setTimeout(() => {
            this.chatscroll.nativeElement.scrollTop =
                this.chatscroll.nativeElement.scrollHeight;
        }, 10);
        // });
    }

    onKeyDown(event) {
        event.preventDefault();
    }

    /**
     * Reset the chat
     */
    resetChat(): void {
        this.messages = [];

        this.drawer.close();

        // Close the contact info in case it's opened
        this.drawerOpened = false;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Toggle mute notifications
     */
    // toggleMuteNotifications(): void {
    //     // Toggle the muted
    //     this.chat.muted = !this.chat.muted;

    //     // Update the chat on the server
    //     this._chatService.updateChat(this.chat.id, this.chat).subscribe();
    // }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
