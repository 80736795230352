import { DateTime } from 'luxon';

export enum DaysOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export enum ROUTINE_SLOT {
    MORNING = 'MORNING',
    NOON = 'NOON',
    EVENING = 'EVENING',
    NIGHT = 'NIGHT',
}

export const convertMinsToHrsMins = (mins) => {
    if (!mins) {
        return null;
    }
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
};

export const convertMinsToHrsMinswithLabel = (mins) => {
    if (!mins) {
        return null;
    }
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    if (m == 0) {
        return `${h}h`;
    }
    return `${h}h ${m}m`;
};

export const getFormatedDateFromString = (
    dateString: string,
    fromFormat,
    isUTC: boolean
) => {
    const date = new Date();
    date.setHours(+dateString.split(':')[0]);
    date.setMinutes(+dateString.split(':')[1]);
    date.setSeconds(0);
    return date;
};

export const isDateInTimeRange = (
    selectedDate: Date,
    startTime: String,
    endTime: String,
    isUTC: boolean
) => {
    const startDate = new Date();
    startDate.setHours(+startTime.split(':')[0]);
    startDate.setMinutes(+startTime.split(':')[1]);

    const endDate = new Date();
    endDate.setHours(+endTime.split(':')[0]);
    endDate.setMinutes(+endTime.split(':')[1]);

    return selectedDate >= startDate && selectedDate <= endDate;
};

export const convertH2M = (timeInHour) => {
    var timeParts = timeInHour.split(':');
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
};

export const kilogramToGram = (kg) => {
    return kg * 1000;
};

export const gramToKilogram = (gram) => {
    return gram / 1000;
};

export const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );
    return JSON.parse(jsonPayload);
};

export const includesAny = (arr, values) => values.some((v) => arr.includes(v));

export const recordedAt = (recordedAt) =>
    DateTime.fromISO(recordedAt).toFormat('ccc DD, T');
