import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { IEmergencyAgentTokenValidityDTO } from './emergency-agent-token-validity.model';
import {
    EntityArrayResponseType,
    EntityResponseType,
} from 'app/core/api/geo-location.service';
import { IGroupChatMessage } from 'app/modules/shared/models/group-chat-message.model';
import { createRequestOption } from 'app/core/request/request-util';
import { ReportingService } from 'app/modules/admin/reporting/reporting.service';
import { IReportData } from 'app/modules/admin/reporting/report-data.model';
import { recordedAt } from 'app/modules/shared/models/common';

@Injectable({
    providedIn: 'root',
})
export class EmergencyAgentAccessService {
    protected repoURL = environment.repoUrl;
    private resourceUrl = this.repoURL + '/api/emergency-agent-token';
    private EmergencyStatusResourceUrl = this.repoURL + '/api/user';
    private resourceUrlGroupChatMessage =
        this.repoURL + '/api/group-chat-messages';
    private reportingResourceUrl = this.repoURL + '/api/reports';

    private eaaTokenSubject = new BehaviorSubject<string>('');

    private _groupChatMessages = new BehaviorSubject<IGroupChatMessage[]>([]);

    get groupChatMessages$(): Observable<IGroupChatMessage[]> {
        return this._groupChatMessages.asObservable();
    }

    constructor(
        private http: HttpClient,
        private reportingService: ReportingService
    ) {}

    setEaaTokenSubject(value: string): void {
        this.eaaTokenSubject.next(value);
    }

    getEaaTokenSubject(): BehaviorSubject<string> {
        return this.eaaTokenSubject;
    }

    isValidEmergencyAgentToken(
        emergencyAgentToken: string
    ): Observable<IEmergencyAgentTokenValidityDTO> {
        return this.http.get<IEmergencyAgentTokenValidityDTO>(
            `${this.resourceUrl}/is-valid/${emergencyAgentToken}`
        );
    }

    reCallUserWithEmergency(emergencyAgentToken: string): Observable<number> {
        return this.http.get<number>(
            `${this.EmergencyStatusResourceUrl}/add-user-to-conference/emergency-agent-token/${emergencyAgentToken}`
        );
    }

    reCallAgentWithEmergency(emergencyAgentToken: string): Observable<number> {
        return this.http.get<number>(
            `${this.EmergencyStatusResourceUrl}/add-agent-to-conference/emergency-agent-token/${emergencyAgentToken}`
        );
    }

    reEstablishTheCall(emergencyAgentToken: string): Observable<number> {
        return this.http.get<number>(
            `${this.EmergencyStatusResourceUrl}/re-establish-the-call/emergency-agent-token/${emergencyAgentToken}`
        );
    }

    queryChatsByEmergencyAgentToken(
        emergencyAgentToken: string,
        req?: any
    ): Observable<IGroupChatMessage[]> {
        // ): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http
            .get<IGroupChatMessage[]>(
                `${this.resourceUrlGroupChatMessage}/emergency-agent-token/${emergencyAgentToken}`,
                {
                    params: options,
                    observe: 'response',
                }
            )
            .pipe(
                map((res: EntityArrayResponseType) => {
                    const data = this.convertDateArrayFromServer(res);
                    this._groupChatMessages.next(data);
                    return data;
                })
            );
    }

    createChatByEmergencyAgentToken(
        emergencyAgentToken: string,
        chatMsgText: string
    ): Observable<EntityResponseType> {
        return this.http.post<IGroupChatMessage>(
            `${this.resourceUrlGroupChatMessage}/emergency-agent-token/${emergencyAgentToken}`,
            chatMsgText,
            { observe: 'response' }
        );
    }

    queryAllReportDataAndDownloadReportByEmergencyAgentToken(
        emergencyAgentToken: string,
        lastConfigurableBPDays: number
    ): void {
        if (!emergencyAgentToken) {
            return;
        }

        this.queryAllReportData(
            emergencyAgentToken,
            lastConfigurableBPDays
        ).subscribe(
            (data) => {
                const reportResponseData = data.body;
                this.reportingService.downloadReport(null, reportResponseData);
            },
            (error) => {
                console.error('Error:', error);
            }
        );
    }

    queryAllReportData(
        emergencyAgentToken: string,
        lastConfigurableBPDays: number
    ): Observable<HttpResponse<IReportData>> {
        return this.http.get<IReportData>(
            `${this.reportingResourceUrl}/emergency-agent-token/${emergencyAgentToken}/bp-days/${lastConfigurableBPDays}`,
            { observe: 'response' }
        );
    }

    protected convertDateArrayFromServer(
        res: EntityArrayResponseType
    ): IGroupChatMessage[] {
        // ): EntityArrayResponseType {
        if (res.body) {
            res.body.forEach((groupChatMessage: IGroupChatMessage) => {
                groupChatMessage.createdAt = groupChatMessage.createdAt
                    ? recordedAt(groupChatMessage.createdAt)
                    : undefined;
            });
        }
        return res.body;
    }
}
