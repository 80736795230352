import {
    Component,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { EmergencyAgentAccessService } from './emergency-agent-access.service';
import { GeoLocationService } from 'app/core/api/geo-location.service';
import { GeoFenceService } from 'app/core/api/geo-fence.service';
import {
    GeoLocation,
    IGeoLocation,
} from 'app/modules/shared/models/geo-location.model';
import { GeoFence, IGeoFence } from 'app/modules/shared/models/geo-fence.model';
import { UserManagementService } from 'app/core/api/user.service';
import { AdditionalUserDataService } from 'app/core/api/additional-user-data.service';
import { IGenericDTO } from 'app/modules/shared/models/generic-dto.model';
import { IEmergency } from 'app/modules/shared/models/emergency.model';
import { User } from 'app/modules/shared/models/user-management.model';
import { HttpResponse } from '@angular/common/http';
import { EmergencyService } from 'app/core/api/emergency.service';
import { IGroupChatMessage } from 'app/modules/shared/models/group-chat-message.model';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
    GoogleMap,
    MapAdvancedMarker,
    MapCircle,
    MapGeocoder,
    MapInfoWindow,
    MapMarker,
    MapPolyline,
} from '@angular/google-maps';
import { GroupChatComponent } from './group-chat/group-chat.component';
import { DateTime } from 'luxon';
import { MatIcon } from '@angular/material/icon';
import { CommonService } from 'app/modules/shared/services/common';

@Component({
    selector: 'app-emergency-agent-access',
    standalone: true,
    imports: [
        TranslocoModule,
        MatSidenavModule,
        MatButtonModule,
        MatIcon,
        GoogleMap,
        MapAdvancedMarker,
        MapInfoWindow,
        MapCircle,
        MapPolyline,
        GroupChatComponent,
    ],
    templateUrl: './emergency-agent-access.component.html',
    styleUrl: './emergency-agent-access.component.scss',
})
export class EmergencyAgentAccessComponent implements OnInit {
    // @ViewChild('messageInput') messageInput: ElementRef;
    drawerMode: 'over' | 'side' = 'over';
    drawerOpened: boolean = false;
    @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

    @ViewChildren(MapInfoWindow)
    infoWindowsView!: QueryList<MapInfoWindow>;

    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

    groupChatMessages?: IGroupChatMessage[];

    label1 = {
        color: '#000',
        fontSize: '28px',
        fontWeight: '600',
        text: '1',
    };

    label2 = {
        color: '#000',
        fontSize: '12px',
        fontWeight: '600',
        text: '2',
    };

    label3 = {
        color: '#000',
        fontSize: '12px',
        fontWeight: '600',
        text: '3',
    };

    label4 = {
        color: '#000',
        fontSize: '12px',
        fontWeight: '600',
        text: '4',
    };

    label5 = {
        color: '#000',
        fontSize: '12px',
        fontWeight: '600',
        text: '5',
    };

    mIcon = {
        path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
        fillOpacity: 1,
        fillColor: '#6B5',
        strokeOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#333',
        scale: 12,
    };

    token = '-1';
    isLoading = false;
    isLoadingGps = false;
    isRequestingNewLocation = false;
    isLoadingGeofence = false;
    userWithEmergency = new User();
    isValidToken = false;
    // center: google.maps.LatLngLiteral = { lat: 51.1657, lng: 10.4515 };

    options: google.maps.MapOptions = {
        // center: { lat: 24.85, lng: 67.04 },
        // center: { lat: -31, lng: 147 },
        mapId: 'DEMO_MAP_ID',
        zoom: 17,
        mapTypeId: 'satellite'
    };

    // markerOptions: google.maps.MarkerOptions = { draggable: false };
    markerOptions: google.maps.marker.AdvancedMarkerElementOptions;

    circleOptions: google.maps.CircleOptions;
    geofenceCircleOptions: google.maps.CircleOptions = { fillColor: 'green' };
    markerPositions: google.maps.LatLngLiteral[] = [];
    markerPositionsTmp: google.maps.LatLngLiteral[] = [];
    markerGeofenceCenters: google.maps.LatLngLiteral[] = [];
    markerGeofenceCentersTmp: google.maps.LatLngLiteral[] = [];
    circlePositions: google.maps.Circle[] = [];
    circleGeofences: google.maps.Circle[] = [];
    openedMapInfoWindow?: MapInfoWindow;
    openedMapInfoWindowGeofence?: MapInfoWindow;
    lastGeolocations: GeoLocation[] | null = [];
    listGeofences: GeoFence[] | null = [];
    infoContent!: string;
    showAccuracy = true;
    selectedMarkerIndex = -1;
    selectedRowIndex = -1;
    userEmergencyId?: IGenericDTO;
    emergency?: IEmergency;

    // possible values for recallUserResult and recallAgentResult:
    // -2: default value if there was no call to reCallUserWithEmergency()
    // -1: no current emergency for this user
    // 0: user was called again to join the conf call
    // 1: use is already in the conf call
    reEstablishTheCallResult = -22;

    // possible values for requestGeolocationUpdate:
    // -2: default value if there was no call to updateGeolocationDisplay()
    // -1: no current emergency for this user
    // 0: geolocation update was requested successfully
    // 1: Please wait at least 1 minute between each 2 geo location update requests
    // 2: no mobilityConfig for this user
    updateGeolocationDisplayResult = -2;
    lastConfigurableBPDays = 90;

    // redMarker = () =>
    //     new google.maps.marker.PinElement({
    //         background: '#8D1010',
    //         borderColor: '#8D1010',
    //         glyphColor: 'white',
    //     });

    // darkGreenMarker = () =>
    //     new google.maps.marker.PinElement({
    //         background: '#113523',
    //         borderColor: '#113523',
    //         glyphColor: 'white',
    //     });

    // lightGreenMarker = () =>
    //     new google.maps.marker.PinElement({
    //         background: '#7FB591',
    //         borderColor: '#7FB591',
    //         glyphColor: 'white',
    //     });

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private userService: UserManagementService,
        private emergencyAgentAccessService: EmergencyAgentAccessService,
        private geoLocationService: GeoLocationService,
        private geoFenceService: GeoFenceService,
        private additionalUserDataService: AdditionalUserDataService,
        protected emergencyService: EmergencyService,
        private geocoder: MapGeocoder,
        private _commonService: CommonService
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            const token = params.eaa;
            if (token === undefined) {
                this.emergencyAgentAccessService
                    .getEaaTokenSubject()
                    .subscribe((eaaToken) => {
                        if (!eaaToken) {
                            this.reload();
                        } else {
                            this.token = eaaToken;
                            this.isValidEmergencyAgentToken();
                        }
                    });
            } else {
                this.token = token;
                this.isValidEmergencyAgentToken();
            }
        });
    }

    getGeocodeWithLocation(location: google.maps.LatLngLiteral) {
        return this.geocoder.geocode({
            location,
        });
    }

    addMarker(lastGeolocation: IGeoLocation): void {
        const marker = new google.maps.LatLng(
            lastGeolocation.latitude ? lastGeolocation.latitude : 0,
            lastGeolocation.longitude ? lastGeolocation.longitude : 0
        );

        const circle = new google.maps.Circle();
        const markerCenter: google.maps.LatLngLiteral = {
            lat: lastGeolocation.latitude ? lastGeolocation.latitude : 0,
            lng: lastGeolocation.longitude ? lastGeolocation.longitude : 0,
        };
        circle.setCenter(markerCenter);
        circle.setRadius(
            lastGeolocation.accuracy ? lastGeolocation.accuracy : 0
        );

        const jsonMarker = marker.toJSON();
        this.markerPositionsTmp.push(jsonMarker);
        this.circlePositions.push(circle);
        // this.center = jsonMarker;
    }

    addGeofence(geoFence: IGeoFence): void {
        const circle = new google.maps.Circle();
        const geofenceCenter: google.maps.LatLngLiteral = {
            lat: geoFence.latitude ? geoFence.latitude : 0,
            lng: geoFence.longitude ? geoFence.longitude : 0,
        };
        circle.setCenter(geofenceCenter);
        circle.setRadius(
            geoFence.movementRadiusInMeter ? geoFence.movementRadiusInMeter : 0
        );
        this.circleGeofences.push(circle);

        const markerGeofenceCenter = new google.maps.LatLng(
            geoFence.latitude ? geoFence.latitude : 0,
            geoFence.longitude ? geoFence.longitude : 0
        );
        const jsonGeofenceCenter = markerGeofenceCenter.toJSON();
        this.markerGeofenceCentersTmp.push(jsonGeofenceCenter);
    }

    updateGeolocationDisplay(): void {
        this.geoLocationService
            .sendGeolocationUpdateRequestByEmergencyAgentToken(this.token)
            .subscribe((result) => {
                this.updateGeolocationDisplayResult = result;
                setTimeout(() => {
                    this.updateGeolocationDisplayResult = -2;
                }, 10000);

                this.isRequestingNewLocation = true;
                setTimeout(() => {
                    this.updateGps();
                    this.isRequestingNewLocation = false;
                }, 8000);
            });
    }

    displayHealthReport(): void {
        this.emergencyAgentAccessService.queryAllReportDataAndDownloadReportByEmergencyAgentToken(
            this.token,
            this.lastConfigurableBPDays
        );
    }

    removeDuplicateLocations() {
        this.markerPositions.forEach((location: any, index) => {
            location.isDuplicate = false;
            if (index) {
                location.isDuplicate = this.coordinatesAreClose(
                    this.markerPositions[index - 1],
                    location
                );
            }
        });

        const lastGeolocations: any = this.markerPositions.filter(
            (l: any) => !l.isDuplicate
        );
        this.lastGeolocations = lastGeolocations;
        console.log(this.lastGeolocations);
    }

    updateGps(): void {
        this.isLoadingGps = true;
        this.markerPositions = [];
        this.markerPositionsTmp = [];
        this.circlePositions = [];
        this.markerGeofenceCenters = [];
        this.markerGeofenceCentersTmp = [];
        this.circleGeofences = [];

        this.geoLocationService
            .findLastLocationsByEmergencyAgentToken(this.token)
            .subscribe((received) => {
                this.isLoadingGps = false;
                // for (const lastGeolocation of this.lastGeolocations!) {
                //     this.addMarker(lastGeolocation);
                // }
                // this.markerPositions = this.markerPositionsTmp;
                this.markerPositions = received.body.map((l) => {
                    return {
                        lat: l.latitude,
                        lng: l.longitude,
                        ...l,
                    };
                });

                this.removeDuplicateLocations();
                const { latitude, longitude, accuracy } =
                    this.lastGeolocations[0];
                const position = { lat: latitude, lng: longitude };
                this.options.center = position;
                this.circleOptions = {
                    strokeColor: '#000000',
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    fillColor: '#000000',
                    fillOpacity: 0.2,
                    // editable: true,
                    // draggable: true,
                    // clickable: true,
                    radius: accuracy ? accuracy : 0,
                    center: position,
                };
                setTimeout(() => this.createMarkers(), 100);
            });

        // this.geoFenceService
        //     .findGeofencesByEmergencyAgentToken(this.token)
        //     .subscribe(
        //         (received) => {
        //             this.isLoadingGeofence = false;
        //             this.listGeofences = received.body;
        //             console.log(this.listGeofences);
        //             for (const geofence of this.listGeofences!) {
        //                 this.addGeofence(geofence);
        //             }
        //             this.markerGeofenceCenters = this.markerGeofenceCentersTmp;
        //         },
        //         () => (this.isLoadingGeofence = false)
        //     );
    }

    createMarkers() {
        console.log(this.lastGeolocations);
        this.lastGeolocations.forEach((location: any, index) => {
            // if (!google.maps?.marker?.PinElement) {
            //     return;
            // }
            console.log(index);
            const location_dark_red = new google.maps.marker.PinElement({
                background: '#8D1010',
                borderColor: '#8D1010',
                glyphColor: 'white',
            });
            const location_dark_green = new google.maps.marker.PinElement({
                background: '#113523',
                borderColor: '#113523',
                glyphColor: 'white',
            });
            const location_light_green = new google.maps.marker.PinElement({
                background: '#7FB591',
                borderColor: '#7FB591',
                glyphColor: 'white',
            });
            let markerPin: google.maps.marker.PinElement = location_dark_green;
            if (index === 0) {
                // markerPin = location_dark_green;
                markerPin = location_dark_red;
            } else {
                if (location.accuracy < 20) {
                    markerPin = location_dark_green;
                } else {
                    markerPin = location_light_green;
                }
            }
            location.content = markerPin.element;
            location.title = index;
            console.log('Marker');
        });
    }

    reEstablishTheCall(): void {
        this.isLoading = true;

        this.emergencyAgentAccessService
            .reEstablishTheCall(this.token)
            .subscribe((received) => {
                this.isLoading = false;
                this.reEstablishTheCallResult = received;
                setTimeout(() => {
                    this.reEstablishTheCallResult = -22;
                }, 10000);
            });
    }

    loadUserWithEmergency(): void {
        this.isLoading = true;

        this.userService
            .findByEmergencyAgentToken(this.token)
            .subscribe((received) => {
                this.isLoading = false;
                this.userWithEmergency = received;
            });
    }

    isValidEmergencyAgentToken(): void {
        this.isLoading = true;
        this.emergencyAgentAccessService
            .isValidEmergencyAgentToken(this.token)
            .subscribe({
                next: (res2) => {
                    this.isValidToken = res2.valid ? res2.valid : false;
                    if (this.isValidToken) {
                        this.loadUserWithEmergency();
                        this.updateGps();
                        this.loadChats();
                        this.loadUserEmergencyId();
                        this.loadEmergency();

                        setInterval(() => {
                            this.updateGps();
                            this.loadChats();
                        }, 60000);
                    } else {
                        this.reload();
                    }
                },
                error: () => {
                    this.reload();
                },
            });
    }

    // mapMarkedClicked(marker: MapAdvancedMarker, windowIndex: number): void {
    //     this.selectedMarkerIndex = windowIndex;
    //     this.openInfoWindow(marker, windowIndex);

    //     // const nearbyMarkers = this.getNearbyMarkers(marker, 2);
    //     // for (const m of nearbyMarkers) {
    //     //   // alert(m.lat.toString() + ";" + m.lng.toString());
    //     // }

    //     this.selectedRowIndex = windowIndex;
    // }

    // getNearbyMarkers(marker: MapMarker, radius: number): google.maps.LatLngLiteral[] {
    //   const nearbyMarkers: google.maps.LatLngLiteral[] = [];

    //   for (const m of this.markerPositions) {
    //     const distance = google.maps.geometry.spherical.computeDistanceBetween(
    //       new google.maps.LatLng(marker.getPosition()!.lat(), marker.getPosition()!.lng()),
    //       new google.maps.LatLng(m.lat, m.lng)
    //       );

    //     if (distance <= radius) {
    //       nearbyMarkers.push(m);
    //     }
    //   }
    //   return nearbyMarkers;
    // }

    onMarkerClick(marker: MapAdvancedMarker, location): void {
        if (this.openedMapInfoWindow) {
            this.openedMapInfoWindow.close();
        }
        this.infoContent = '';
        console.log(this.userWithEmergency);
        const accuracy = location ? location.accuracy : '';
        const recordedAt = location ? location.recordedAt : '';
        const lat = location ? location.latitude : '';
        const lng = location ? location.longitude : '';
        const formattedDate = recordedAt
            ? // ? DateTime.fromISO(recordedAt).toFormat('D MMM yyyy HH:mm:ss')
              DateTime.fromISO(recordedAt).toFormat('DD MM yyyy hh:mm:ss')
            : '';

        const name =
            this.userWithEmergency.firstName +
            ' ' +
            this.userWithEmergency.lastName;
        let street = null;
        this.getGeocodeWithLocation({
            lat: location.latitude,
            lng: location.longitude,
        }).subscribe(({ results }) => {
            let address = null;
            if (results[0].formatted_address) {
                const zipCode = results[0].address_components.find((a) =>
                    a.types.includes('postal_code')
                )?.long_name;
                const city = results[0].address_components.find((a) =>
                    a.types.includes('locality')
                )?.long_name;
                const country = results[0].address_components.find((a) =>
                    a.types.includes('country')
                )?.long_name;
                // address = 'Address: ' + results[0].formatted_address;
                address = zipCode + ' ' + city + ', ' + country;

                const streetNumber = results[0].address_components.find((a) =>
                    a.types.includes('street_number')
                )?.long_name;
                if (streetNumber) {
                    street =
                        results[0].address_components.find((a) =>
                            a.types.includes('route')
                        )?.long_name +
                        ' ' +
                        streetNumber;
                } else {
                    street = results[0].address_components[0]?.long_name;
                }
            }
            this.infoWindow.infoWindow.setContent(`
                <div>
                    <div class="text-lg font-semibold">${name}</div>
                    ${recordedAt ? formattedDate + '<br/>' : ''}
                    ${street ? street + '</br>' : ''}
                    ${address + '</br>'}
                    ${
                        'Breite/Längengrad: ' +
                        lat!.toString() +
                        '/' +
                        lng!.toString() +
                        '  <br/>'
                    }
                    ${
                        accuracy
                            ? 'Genauigkeit: ' + accuracy.toString() + ' m <br/>'
                            : ''
                    }
                    <div>Position:  <a class="underline"
                                    href="https://www.google.com/maps/search/?api=1&query=${
                                        location.latitude
                                    },${location.longitude}"
                                    target="_blank">
                                    ${location.latitude},${location.longitude}
                                    </a>
                    </div>
                    </br>
                    <button id="nearest-btn" class="mb-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full" type="button">
                        ${this._commonService.t('NEAREST-EMERGENCY-STATION')}
                    </button>
                    <div id="nearest-emergency"></div>
                </div>
                `);

            // this.openedMapInfoWindow = window;
            this.infoWindow.open(marker);

            setTimeout(() => {
                const button = document.getElementById('nearest-btn');
                button.onclick = () => {
                    console.log(location);
                    const params = {
                        latitude: lat,
                        longitude: lng,
                    };
                    this.emergencyService
                        .getNearestToGeolocation(params)
                        .subscribe((res) => {
                            console.log(res);
                            const nearestEmergencyDiv =
                                document.getElementById('nearest-emergency');
                            const nearestEmergencyDivContent = `
                                <div> ${res.name}</div>
                                <div> ${res.areaType}</div>
                                <div> ${res.operator}</div>
                                <div> ${res.address}</div>
                                <div> ${res.zipCode} ${res.city}</div>
                                 ${
                                     res.phoneNumber1
                                         ? '<div>' + res.phoneNumber1 + '</div>'
                                         : ''
                                 }
                                 ${
                                     res.phoneNumber2
                                         ? '<div>' + res.phoneNumber2 + '</div>'
                                         : ''
                                 }
                                 ${
                                     res.phoneNumber3
                                         ? '<div>' + res.phoneNumber3 + '</div>'
                                         : ''
                                 }
                                <div> ${res.email}</div>
                                <div> <a  href="https://${
                                    res.website
                                }" target="_blank">${res.website}</a></div>
                                <div> <a class="underline"
                                                    href="https://www.google.com/maps/search/?api=1&query=${
                                                        res.latitude
                                                    },${res.longitude}"
                                                    target="_blank">${
                                                        res.latitude
                                                    },${res.longitude}</a>
                                </div>
                           `;
                            nearestEmergencyDiv.innerHTML =
                                nearestEmergencyDivContent;
                        });
                };
            }, 500);
        });
    }

    handleLinkMarkerClick(indexClicked: number): void {
        // Do something with the variable
        // console.log('Link clicked with variable:', myVariable);
        alert(indexClicked);
    }

    openInfoWindowGeoFence(marker: MapMarker, windowIndex: number): void {
        if (this.openedMapInfoWindowGeofence) {
            this.openedMapInfoWindowGeofence.close();
        }

        this.infoContent = '';
        this.infoWindowsView.forEach((window: MapInfoWindow) => {
            const geofence = this.listGeofences
                ? this.listGeofences[windowIndex]
                : null;
            const locationName = geofence ? geofence.locationName : '';
            this.infoContent = locationName
                ? 'Standortnamen: ' + locationName + '<br/>'
                : '';
            this.openedMapInfoWindow = window;
            window.open(marker);

            return;
        });
    }

    getMarkerLabel(markerNumber: number, allMarkerNumber: number): any {
        if (allMarkerNumber >= 5) {
            if (markerNumber === 0) {
                return this.label5;
            } else if (markerNumber === 1) {
                return this.label4;
            } else if (markerNumber === 2) {
                return this.label3;
            } else if (markerNumber === 3) {
                return this.label2;
            } else {
                return this.label1;
            }
        } else if (allMarkerNumber === 4) {
            if (markerNumber === 0) {
                return this.label4;
            } else if (markerNumber === 1) {
                return this.label3;
            } else if (markerNumber === 2) {
                return this.label2;
            } else {
                return this.label1;
            }
        } else if (allMarkerNumber === 3) {
            if (markerNumber === 0) {
                return this.label3;
            } else if (markerNumber === 1) {
                return this.label2;
            } else {
                return this.label1;
            }
        } else if (allMarkerNumber === 2) {
            if (markerNumber === 0) {
                return this.label2;
            } else {
                return this.label1;
            }
        } else if (allMarkerNumber === 1) {
            return this.label1;
        }
    }

    loadChats(): void {
        this.isLoading = true;

        // this.emergencyAgentAccessService
        //     .queryChatsByEmergencyAgentToken(this.token)
        //     .subscribe(
        //         (res: HttpResponse<IGroupChatMessage[]>) => {
        //             this.isLoading = false;
        //             this.groupChatMessages = res.body.reverse() ?? [];
        //         },
        //         () => {
        //             this.isLoading = false;
        //         }
        //     );

        this.emergencyAgentAccessService
            .queryChatsByEmergencyAgentToken(this.token)
            .subscribe(
                (res: IGroupChatMessage[]) => {
                    this.isLoading = false;
                    // this.groupChatMessages = res.reverse() ?? [];
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    loadUserEmergencyId(): void {
        this.additionalUserDataService
            .getUserEmergencyIdByEmergencyAgentToken(this.token)
            .subscribe((res: HttpResponse<IGenericDTO>) => {
                this.userEmergencyId = res.body ?? {};
            });
    }

    loadEmergency(): void {
        if (this.emergency == null) {
            this.emergencyService
                .getUserEmergencyIdByEmergencyAgentToken(this.token)
                .subscribe((res: HttpResponse<IEmergency>) => {
                    this.emergency = res.body ?? {};
                });
        }

        setTimeout(() => {
            this.isRequestingNewLocation = false;
            this.updateGps();
            this.isRequestingNewLocation = false;
            if (this.emergency == null) {
                this.loadEmergency();
            }
        }, 8000);
    }

    trackId(index: number, item: IGroupChatMessage): number {
        return item.id!;
    }

    sendMessage(message) {
        this.isLoading = true;
        this.emergencyAgentAccessService
            .createChatByEmergencyAgentToken(this.token, message)
            .subscribe(
                () => this.onSaveSuccess(),
                () => this.onSaveError()
            );
    }

    // isGeolocationUpdateEnabled(): boolean {
    //     if (!this.emergency?.startedAt) {
    //         return false;
    //     }
    //     const currentTime = dayjs();
    //     const timeDifference = currentTime.diff(
    //         this.emergency.startedAt,
    //         'second'
    //     );
    //     return timeDifference > 80;
    // }

    protected onSaveSuccess(): void {
        this.loadChats();
        this.isLoading = false;
    }

    protected onSaveError(): void {
        this.isLoading = false;
    }

    /**
     * Open the Group chat
     */
    openGroupChat(): void {
        // Open the drawer
        this.drawerOpened = true;

        // Mark for check
        // this._changeDetectorRef.markForCheck();
    }

    reload() {
        // this.router.navigate(['']);
        window.location.href = '/';
    }

    private coordinatesAreClose(coord1, coord2, tolerance = 0.0001) {
        let deltaLat = Math.abs(coord1.latitude - coord2.latitude);
        let deltaLon = Math.abs(coord1.longitude - coord2.longitude);
        return deltaLat < tolerance && deltaLon < tolerance;
    }
}
