import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportingCommonService } from './reporting-common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingPage5Service {
  constructor(private reportingCommonService: ReportingCommonService) {}

  render(doc: jsPDF, data: any, nbPage: number): number {
    doc.setPage(nbPage);

    let startX = 42;
    const texts = ['Blutdruck und Puls', ' letzte', ' 30', ' Tage'];
    doc.setFontSize(14);
    texts.forEach((text, index) => {
      this.reportingCommonService.setNormalFont(doc);
      if (index % 2 === 0) {
        this.reportingCommonService.setBoldFont(doc);
      }
      doc.text(text, startX, 75);
      startX += doc.getTextWidth(text);
    });

    // add a rectangle
    let x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    doc.rect(x, 50, doc.internal.pageSize.width - x * 2, doc.internal.pageSize.height - 95);

    // add the chart left titles
    x = 42;
    let titleY = 155;
    let title = 'Systolisch';
    doc.setFontSize(10);
    this.reportingCommonService.setBoldFont(doc);
    doc.text(title, x, titleY);

    titleY = 245;
    title = 'Diastolisch';
    doc.text(title, x, titleY);

    titleY = 295;
    title = 'Puls';
    doc.text(title, x, titleY);

    const chartPaddingLeft = 90;
    this.reportingCommonService.drawAreaChart(
      doc,
      data.data30DaysSystolic,
      x + chartPaddingLeft, // x
      100, // y
      doc.internal.pageSize.width - x * 2 - chartPaddingLeft - 10, // width
      205, // height
      160, // maxY
      [100, 130, 154], // drawColor
      [174, 198, 228], // fillColor
      true,
      30
    );

    this.reportingCommonService.drawAreaChart(
      doc,
      data.data30DaysDiastolic,
      x + chartPaddingLeft,
      100,
      doc.internal.pageSize.width - x * 2 - chartPaddingLeft - 10,
      205,
      160,
      [78, 114, 98],
      [180, 212, 191],
      false,
      30
    );

    this.reportingCommonService.drawAreaChart(
      doc,
      data.data30DaysPulse,
      x + chartPaddingLeft,
      100,
      doc.internal.pageSize.width - x * 2 - chartPaddingLeft - 10,
      205,
      160,
      [190, 2, 114],
      [190, 2, 114],
      false,
      30
    );

    // bar chart
    const tableX = 30;
    const chartWidth = 140;
    let xPosition = tableX + 100;
    const xPadding = 40;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.values30DaySystolicDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [174, 198, 228],
      true,
      'Systolisch',
      '',
      data.xTicks30DaySystolicDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.values30DayDiastolicDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [180, 212, 191],
      true,
      'Diastolisch',
      '',
      data.xTicks30DayDiastolicDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.values30DayPulseDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [190, 2, 114],
      true,
      'Puls',
      '',
      data.xTicks30DayPulseDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.values24HoursPulseDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [190, 2, 114],
      true,
      '24h-Puls',
      '',
      data.xTicks24HoursPulseDeltaChart
    );

    xPosition += chartWidth + xPadding;
    this.reportingCommonService.addDeltaChart(
      doc,
      data.values30DayDruckDeltaChart,
      xPosition,
      doc.internal.pageSize.height - 225,
      chartWidth,
      80,
      [190, 2, 114],
      true,
      'Pulsdruck',
      '',
      data.xTicks30DayDruckDeltaChart
    );

    const tableHeight = 80;
    this.reportingCommonService.drawSpatialTable(
      doc,
      x,
      doc.internal.pageSize.height - tableHeight - 45,
      doc.internal.pageSize.width - 2 * x - 105,
      tableHeight,
      data.table
    );

    return nbPage;
  }
}
