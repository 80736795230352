import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportingCommonService } from './reporting-common.service';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root',
})
export class ReportingPage4Service {
  constructor(private reportingCommonService: ReportingCommonService) {}

  render(doc: jsPDF, data: any, nbPage: number): number {
    const { calendar } = data;
    calendar.forEach(function (row: any) {
      if (row.value >= 10 && row.value < 101) {
        row.value = 9;
      } else if (row.value >= 101) {
        row.value = 10;
      }
    });

    doc.setPage(nbPage);

    // add a rectangle
    let x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    doc.rect(x, 50, doc.internal.pageSize.width - x * 2, doc.internal.pageSize.height / 2 - 65, 'FD');

    this.reportingCommonService.drawTable(doc, {
      x: 42,
      y: 65,
      title1: 'Gewicht',
      title2: 'letzte 30 Tage',
    });

    const startY = 70;
    // draw indicators to right
    const indicators = [
      { name: 'Ziel', value: data.table1.bodyWeightTargetInKg },
      { name: 'Max', value: data.table1.last30DayMaxWeightInKg },
      { name: 'Min', value: data.table1.last30DayMinWeightInKg },
      { name: 'Durchschnitt', value: data.table1.last30DayAvgWeightInKg },
      { name: 'Std.Abw.', value: data.table1.last30DayStdDevWeightInKg },
    ];
    this.reportingCommonService.drawIndicator(doc, doc.internal.pageSize.width - 80, startY - 5, indicators);

    // bar chart
    const tableX = 42;
    const isEveryValueEmpty = data.table1.last30DayDeltaFromTargetWeightInKg.every((value: string) => value === '');
    this.reportingCommonService.addDeltaChart(
      doc,
      isEveryValueEmpty ? [] : data.table1.last30DayDeltaFromTargetWeightInKg,
      tableX + 100,
      startY + 15,
      doc.internal.pageSize.width - x * 2 - 160,
      50,
      [174, 198, 228], // fillColor
      false, // addTicks,
      '', // tableTitle
      '', // xLabel
      [] // xTicks
    );

    let title = 'Delta zu ';
    this.reportingCommonService.setBoldFont(doc);
    if (!isEveryValueEmpty) {
      doc.text(title, tableX + 15, startY + 18);
    }
    title = 'Ziel, kg';
    if (!isEveryValueEmpty) {
      doc.text(title, tableX + 15, startY + 36);
    }

    this.reportingCommonService.drawTable(
      doc,
      {
        x: tableX,
        y: 138,
        title1: '',
        title2: '',
        // width: doc.internal.pageSize.width - x * 2,
        indicators: [],
        data: data.table1.data,
        colorTableCell(cellValue: string, rowNumber: number) {
          let color = [255, 255, 255]; // default
          if (
            (rowNumber === 3 && cellValue >= '1') || // 24h: >=1 Kg
            (rowNumber === 4 && cellValue >= '2') || // 72h: >= 2kg
            (rowNumber === 5 && cellValue >= '2.5') // 7day: >=2,5kg
          ) {
            color = [232, 162, 177]; // red if 24h: >=1 Kg
          }

          return color;
        },

        legends: data.table1.legends,
      },
      data.table1.emptyWeightBlock
    );

    // add a rectangle
    x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    let pageHeight = doc.internal.pageSize.height;
    doc.rect(x, 455, doc.internal.pageSize.width - x * 2, 95, 'FD');

    const startY2 = 470;
    // draw indicators to right
    const indicators2 = [
      { name: 'Ziel', value: data.table2.drinkDailyTarget },
      { name: 'Max', value: data.table2.maxDrink },
      { name: 'Min', value: data.table2.minDrink },
      { name: 'Durchschnitt', value: data.table2.avgDrink },
      { name: 'Std.Abw.', value: data.table2.stdDevDrink },
    ];
    this.reportingCommonService.drawIndicator(doc, doc.internal.pageSize.width - 80, startY2 - 10, indicators2);

    this.reportingCommonService.drawTable(
      doc,
      {
        x: 42,
        y: 470,
        title1: 'Trinkmenge, ',
        title2: 'letzte 30 Tage',
        // width: doc.internal.pageSize.width,
        indicators: [],
        data: data.table2.data,
        colorTableCell(cellValue: string, rowNumber: number) {
          let color = [255, 255, 255]; // default
          if (rowNumber === 3 && cellValue) {
            const numericCellValue = parseFloat(cellValue.replace('-', ''));
            if (numericCellValue > 15) {
              color = [232, 162, 177]; // red if abs() >=15
            }
          }

          return color;
        },
        legends: data.table1.legends,
      },
      data.table2.emptyDrinkBlock
    );

    // add title to the top of the page
    this.reportingCommonService.setBoldFont(doc);
    doc.setFontSize(14);
    title = 'Nycturi';
    doc.text(title, 42, pageHeight / 2 + 16);

    // title width
    const titleWidth = doc.getTextWidth(title);
    this.reportingCommonService.setNormalFont(doc);
    doc.text(', Anzahl nächtliche Toilettengänge, letzte 12 Monate', 42 + titleWidth, pageHeight / 2 + 16); // //////

    const colors = [
      '#FFFFFF',
      '#D3E6C6',
      '#AACE92',
      '#84B961',
      '#69A84F',
      '#4F966E',
      '#3E8B82',
      '#2E6F67',
      '#22564A',
      '#113423',
      '#F8F8F8', // default background
    ];
    this.reportingCommonService.Calendar2(calendar, doc, '%b', {
      x: (m: { date: string; value: number }) => new Date(m.date),
      y: (m: { date: string; value: number }) => m.value,
      cellSize: 15,
      xPosition: 48,
      yPosition: pageHeight / 2 + 38,
      useHexConverter: true,
      colorScale: d3.scaleOrdinal<number, string>().domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).range(colors),
    });

    // add a rectangle
    x = 30;
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(255, 255, 255);
    doc.setLineWidth(1);
    pageHeight = doc.internal.pageSize.height;
    doc.rect(x, pageHeight / 2, doc.internal.pageSize.width - x * 2, pageHeight / 4);

    return nbPage;
  }
}
