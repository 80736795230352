import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
// import dayjs from 'dayjs/esm';

// import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { environment } from 'environments/environment';
import { IUserAndEmergencyDTO } from './umo-pstn-fallback-list.model';

export type EntityResponseType = HttpResponse<IUserAndEmergencyDTO>;
export type EntityArrayResponseType = HttpResponse<IUserAndEmergencyDTO[]>;

@Injectable({ providedIn: 'root' })
export class TunstallEmergencyListService {
    protected repoURL = environment.repoUrl;
    protected resourceUrl = this.repoURL + '/api/tunstall-emergency-list';

    constructor(protected http: HttpClient) {}

    findLastTunstallEmergencyList(
        token: string
    ): Observable<EntityArrayResponseType> {
        return this.http
            .get<IUserAndEmergencyDTO[]>(`${this.resourceUrl}/eaf/${token}`, {
                observe: 'response',
            })
            .pipe(
                // map((res: EntityArrayResponseType) =>
                //     this.convertDateArrayFromServer(res)
                // )
            );
    }

    protected convertDateArrayFromServer(
        res: EntityArrayResponseType
    ): EntityArrayResponseType {
        if (res.body) {
            // res.body.forEach((userAndEmergencyDTO: any) => {
            //   userAndEmergencyDTO.startedAt = userAndEmergencyDTO.startedAt ? dayjs(userAndEmergencyDTO.startedAt) : undefined;
            // });
            res.body.forEach((userAndEmergencyDTO: IUserAndEmergencyDTO) => {
                userAndEmergencyDTO.startedAt = userAndEmergencyDTO.startedAt
                    ? userAndEmergencyDTO.startedAt
                    : undefined;
            });
        }
        return res;
    }
}
