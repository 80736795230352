import { AsyncPipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
    Observable,
    Subject,
    delay,
    filter,
    map,
    startWith,
    takeUntil,
    tap,
} from 'rxjs';
import { AccountSelectorService } from './account-selector.service';
import { User } from 'app/core/user/user.types';
import { Pagination } from 'app/modules/shared/models/Account';
import {
    CdkVirtualScrollViewport,
    ScrollDispatcher,
    ScrollingModule,
} from '@angular/cdk/scrolling';
import { UserService } from 'app/core/user/user.service';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'account-selector',
    standalone: true,
    templateUrl: './account-selector.component.html',
    styleUrl: './account-selector.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        AsyncPipe,
        ScrollingModule,
        TranslocoModule,
    ],
})
export class AccountSelectorComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    accounts = [];
    filterAccounts: Observable<User[]>;
    selectedAccount: User;
    baseAccount: User;
    pagination = new Pagination();

    page = 0;

    accountsLoading = false;

    @ViewChild(CdkVirtualScrollViewport, { static: true })
    cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

    @ViewChildren(MatOption)
    options: QueryList<MatOption>;

    accountInputCtrl = new FormControl('');
    private unsubscribe$: Subject<any> = new Subject<any>();

    constructor(
        private cd: ChangeDetectorRef,
        readonly sd: ScrollDispatcher,
        private _accountService: AccountSelectorService,
        private _userService: UserService,
        private _router: Router,
        private splash: FuseSplashScreenService
    ) {}

    ngOnInit(): void {
        this._accountService
            .getAccounts()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe();

        this._accountService.accounts$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((accounts) => {
                if (accounts) {
                    this.accounts = [...this.accounts, ...accounts];
                    this.accountInputCtrl.setValue('');
                    this.cd.detectChanges();
                }
            });

        this._accountService.pagination$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((pagination) => {
                this.pagination = pagination;
                this.page = pagination.currentPage;
            });

        this._userService.user$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((account) => {
                this.baseAccount = account;
                this.selectedAccount = account;
            });

        this._userService.activeUser$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((account) => {
                this.selectedAccount = account;
                const url = this._router.url;
                this.splash.show();
                this._router.navigate(['']).then(() => {
                    this._router.navigate([url]);
                    setTimeout(() => {
                        this.splash.hide();
                    }, 2000);
                });
            });

        this.filterAccounts = this.accountInputCtrl.valueChanges.pipe(
            takeUntil(this.unsubscribe$),
            startWith(''),
            map((value) => this._filter(value || '')),
            delay(100),
            tap(() => this.onlyCheckThoseWhichAreSelected())
        );
    }

    // search() {
    //     return this.accountInputCtrl.valueChanges.pipe(
    //         takeUntil(this.unsubscribe$),
    //         debounceTime(500),
    //         distinctUntilChanged(),
    //         startWith(''),
    //         // map((value) => this._filter(value || '')),
    //         switchMap((value) =>
    //             this._accountService.getAccounts(value, this.page)
    //         )
    //     );
    // }

    ngAfterViewInit(): void {
        // this.search()
        //     .pipe(takeUntil(this.unsubscribe$))
        //     .subscribe((accounts) => {
        //         console.log(accounts);
        //         this.accountsLoading = false
        //         // this.accounts = accounts;
        //         // this.cd.detectChanges();
        //     });

        this.sd
            .scrolled()
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(
                    (scrollable) => this.cdkVirtualScrollViewPort === scrollable
                )
            )
            .subscribe(() => {
                // Don't get new data on scroll if searching is ON.
                if (this.accountInputCtrl.value) {
                    return;
                }

                this.getNewAccounts();
                this.onlyCheckThoseWhichAreSelected();
            });
    }

    getNewAccounts() {
        const start = this.cdkVirtualScrollViewPort.getRenderedRange().start;
        const end = this.cdkVirtualScrollViewPort.getRenderedRange().end;
        const total = this.cdkVirtualScrollViewPort.getDataLength();
        console.log(end);
        console.log(total);

        if (
            // end >= total - 1 &&
            !this.accountsLoading &&
            end === total &&
            this.accounts.length !== this.pagination.totalCount
        ) {
            this.accountsLoading = true;
            this.page = this.page + 1;
            this._accountService
                .getAccounts(this.accountInputCtrl.value, this.page)
                .subscribe(() => (this.accountsLoading = false));
        }
    }

    onlyCheckThoseWhichAreSelected() {
        let needUpdate = false;
        this.options.forEach((option) => {
            const selected = this.selectedAccount.login === option.value.login;

            if (selected && !option.selected) {
                option.select();
                needUpdate = true;
            } else if (!selected && option.selected) {
                option.deselect();
                needUpdate = true;
            }
        });

        if (needUpdate) {
            this.cd.detectChanges();
        }
    }

    onSelectionChange(option: MatOptionSelectionChange) {
        if (!option.isUserInput) {
            return;
        }
        this._userService.activeUser = option.source.value;
        // this.selectedAccount = option.source.value;
    }

    foropen() {
        this.cdkVirtualScrollViewPort?.scrollToIndex(5);
    }

    openChange(isOpen: boolean) {
        this.resetSearch(isOpen);

        if (isOpen) {
            this.foropen();
            this.cdkVirtualScrollViewPort?.scrollToIndex(0);
            this.cdkVirtualScrollViewPort?.checkViewportSize();
        }
    }

    // Filter the states list and send back to populate the selectedStates**
    _filter(value: string) {
        let filter = value.split(' ').join('').toLowerCase();
        return this.accounts.filter((option: User) => {
            const name =
                String(option.firstName).trim() +
                String(option.lastName).trim();
            return name.toLowerCase().includes(filter);
        });
    }

    resetSearch(value: boolean) {
        if (!value) {
            this.accountInputCtrl.reset();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
