import { Component, ViewEncapsulation } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { WifiSettingsService } from 'app/core/api/wifi-settings.service';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-wifi-settings',
    standalone: true,
    templateUrl: './wifi-settings.component.html',
    styleUrl: './wifi-settings.component.scss',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    imports: [
        FuseAlertComponent,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        TranslocoModule,
    ],
})
export class WifiSettingsComponent {
    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: '',
    };
    wifiPasswordForm: UntypedFormGroup;
    showAlert: boolean = false;
    token = null;

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private wifiSettings: WifiSettingsService,
        private route: ActivatedRoute,
        private _translocoService: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams['wst'];
        console.log(this.token);

        // Create the form
        this.initForm();
    }

    initForm() {
        this.wifiPasswordForm = this._formBuilder.group({
            ssid: ['', [Validators.required]],
            password: ['', [Validators.minLength(8)]],
        });

        this.wifiPasswordForm.enable();
        // Reset the form
        this.wifiPasswordForm.reset();
    }

    save(): void {
        console.log(this.wifiPasswordForm);
        const formData = this.wifiPasswordForm.getRawValue();
        console.log(formData, this.token);

        // Return if the form is invalid
        if (this.wifiPasswordForm.invalid) {
            return;
        }

        // Disable the form
        this.wifiPasswordForm.disable();

        // // Hide the alert
        this.showAlert = false;

        this.wifiSettings
            .setWifiPassword(this.token, formData)
            .pipe(
                finalize(() => {
                    // Re-enable the form
                    this.wifiPasswordForm.enable();

                    // Reset the form
                    this.wifiPasswordForm.reset();

                    // Show the alert
                    this.showAlert = true;
                })
            )
            .subscribe(
                (response) => {
                    // Set the alert
                    this.alert = {
                        type: 'success',
                        message: this.t(
                            'THANK-YOU-VERY-MUCH-FOR-SHARING-THE-CONFIGURATION'
                        ),
                    };
                },
                (error) => {
                    // Set the alert
                    this.alert = {
                        type: 'error',
                        message:
                            error.error ||
                            this.t('SOMETHING-WENT-WRONG-PLEASE-TRY-AGAIN'),
                    };
                }
            );
    }

    t(key: string) {
        return this._translocoService.translate(key);
    }
}
