import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import dayjs from 'dayjs/esm';

// import { isPresent } from 'app/core/util/operators';
// import { ApplicationConfigService } from 'app/core/config/application-config.service';
// import { createRequestOption } from 'app/core/request/request-util';
// import { IGeoLocation, getGeoLocationIdentifier } from '../geo-location.model';
// import { IInsightData } from 'app/pages/insights/geolocation-visualizer/insight-data.model';
import { environment } from 'environments/environment';
import { IGeoLocation } from 'app/modules/shared/models/geo-location.model';

export type EntityResponseType = HttpResponse<IGeoLocation>;
export type EntityArrayResponseType = HttpResponse<IGeoLocation[]>;

@Injectable({ providedIn: 'root' })
export class GeoLocationService {
            protected repoURL = environment.repoUrl;
  protected resourceUrl = this.repoURL + '/api/geo-locations';

  constructor(protected http: HttpClient) {}

//   create(geoLocation: IGeoLocation): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(geoLocation);
//     return this.http
//       .post<IGeoLocation>(this.resourceUrl, copy, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   update(geoLocation: IGeoLocation): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(geoLocation);
//     return this.http
//       .put<IGeoLocation>(`${this.resourceUrl}/${getGeoLocationIdentifier(geoLocation) as number}`, copy, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   partialUpdate(geoLocation: IGeoLocation): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(geoLocation);
//     return this.http
//       .patch<IGeoLocation>(`${this.resourceUrl}/${getGeoLocationIdentifier(geoLocation) as number}`, copy, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   find(id: number): Observable<EntityResponseType> {
//     return this.http
//       .get<IGeoLocation>(`${this.resourceUrl}/${id}`, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   query(req?: any): Observable<EntityArrayResponseType> {
//     const options = createRequestOption(req);
//     return this.http
//       .get<IGeoLocation[]>(this.resourceUrl, { params: options, observe: 'response' })
//       .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
//   }

//   queryAll(req?: any): Observable<EntityArrayResponseType> {
//     const options = createRequestOption(req);
//     return this.http
//       .get<IGeoLocation[]>(`${this.resourceUrl}/all`, { params: options, observe: 'response' })
//       .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
//   }

//   queryAllInsightData(req?: any): Observable<EntityArrayResponseType> {
//     const options = createRequestOption(req);
//     return this.http
//       .get<IGeoLocation[]>(`${this.resourceUrl}/all-insight-data`, { params: options, observe: 'response' })
//       .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServerGorInsightData(res)));
//   }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

//   addGeoLocationToCollectionIfMissing(
//     geoLocationCollection: IGeoLocation[],
//     ...geoLocationsToCheck: (IGeoLocation | null | undefined)[]
//   ): IGeoLocation[] {
//     const geoLocations: IGeoLocation[] = geoLocationsToCheck.filter(isPresent);
//     if (geoLocations.length > 0) {
//       const geoLocationCollectionIdentifiers = geoLocationCollection.map(geoLocationItem => getGeoLocationIdentifier(geoLocationItem)!);
//       const geoLocationsToAdd = geoLocations.filter(geoLocationItem => {
//         const geoLocationIdentifier = getGeoLocationIdentifier(geoLocationItem);
//         if (geoLocationIdentifier == null || geoLocationCollectionIdentifiers.includes(geoLocationIdentifier)) {
//           return false;
//         }
//         geoLocationCollectionIdentifiers.push(geoLocationIdentifier);
//         return true;
//       });
//       return [...geoLocationsToAdd, ...geoLocationCollection];
//     }
//     return geoLocationCollection;
//   }

  findLastLocationsByEmergencyAgentToken(emergencyAgentToken: string): Observable<EntityArrayResponseType> {
    return this.http
      .get<IGeoLocation[]>(`${this.resourceUrl}/emergency-agent-token/${emergencyAgentToken}`, { observe: 'response' })
    //   .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  sendGeolocationUpdateRequestByEmergencyAgentToken(emergencyAgentToken: string): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/request-geolocation-update/emergency-agent-token/${emergencyAgentToken}`);
  }

//   protected convertDateFromClient(geoLocation: IGeoLocation): IGeoLocation {
//     return Object.assign({}, geoLocation, {
//       recordedAt: geoLocation.recordedAt?.isValid() ? geoLocation.recordedAt.toJSON() : undefined,
//       acquiredAt: geoLocation.acquiredAt?.isValid() ? geoLocation.acquiredAt.toJSON() : undefined,
//       justLeftGeofenceTime: geoLocation.justLeftGeofenceTime?.isValid() ? geoLocation.justLeftGeofenceTime.toJSON() : undefined,
//     });
//   }

//   protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
//     if (res.body) {
//       res.body.recordedAt = res.body.recordedAt ? dayjs(res.body.recordedAt) : undefined;
//       res.body.acquiredAt = res.body.acquiredAt ? dayjs(res.body.acquiredAt) : undefined;
//       res.body.justLeftGeofenceTime = res.body.justLeftGeofenceTime ? dayjs(res.body.justLeftGeofenceTime) : undefined;
//     }
//     return res;
//   }

//   protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
//     if (res.body) {
//       res.body.forEach((geoLocation: IGeoLocation) => {
//         geoLocation.recordedAt = geoLocation.recordedAt ? dayjs(geoLocation.recordedAt) : undefined;
//         geoLocation.acquiredAt = geoLocation.acquiredAt ? dayjs(geoLocation.acquiredAt) : undefined;
//         geoLocation.justLeftGeofenceTime = geoLocation.justLeftGeofenceTime ? dayjs(geoLocation.justLeftGeofenceTime) : undefined;
//       });
//     }
//     return res;
//   }

//   protected convertDateArrayFromServerGorInsightData(res: EntityArrayResponseType): EntityArrayResponseType {
//     if (res.body) {
//       res.body.forEach((insightData: IInsightData) => {
//         insightData.recordedAt = insightData.recordedAt ? dayjs(insightData.recordedAt) : undefined;
//         insightData.acquiredAt = insightData.acquiredAt ? dayjs(insightData.acquiredAt) : undefined;
//         insightData.justLeftGeofenceTime = insightData.justLeftGeofenceTime ? dayjs(insightData.justLeftGeofenceTime) : undefined;
//         insightData.comparisonTime = insightData.comparisonTime ? dayjs(insightData.comparisonTime) : undefined;
//         insightData.time = insightData.time ? dayjs(insightData.time) : undefined;
//       });
//     }
//     return res;
//   }
}
