import { Component, OnInit } from '@angular/core';
import { TunstallEmergencyListService } from './tunstall-emergency-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, NgClass } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

const dummyData: any = [
    {
        emergencyId: 17061551,
        state: 'IN_PROGRESS',
        startedAt: '2025-02-03T14:07:53.968838Z',
        reason: 'TELEMONITORING_NO_ACTIVITY',
        data: 'raised by admin user: ab_admin ( )',
        source: 'emergency-tracking-page',
        userId: 2,
        login: 'user1',
        firstName: 'User1',
        lastName: 'User1',
        email: 'user1@localhost',
        apNumber: 'T5830920',
        nickName: null,
        title: null,
        salutation: null,
        emergencyColor: 'RED',
        urlAgentAccessToken:
            'https://repository.test.almaphil.net/emergency-center-agent-access?eaa=2pGm%7E%7E%7E5sg6bEVewRrw0z%2BZeXZpaMID7tYs08dnFPhWfTz9JDNyuubUVMdKzpQsCmL',
    },
];
@Component({
    standalone: true,
    selector: 'jhi-tunstall-emergency-list',
    templateUrl: './tunstall-emergency-list.component.html',
    styleUrls: ['./tunstall-emergency-list.component.scss'],
    imports: [TranslocoModule, MatTableModule, NgClass, DatePipe],
})
export class TunstallEmergencyListComponent implements OnInit {
    // lastUmoPstnFallbackList: UserAndEmergencyDTO[] | null = [];
    isLoading = false;
    token = '-1';

    displayedColumns: string[] = [
        'startedAt',
        'reason',
        'apNumber',
        'salutation',
        'title',
        'nickName',
        'firstName',
        'lastName',
    ];

    displayedColumnsData = {
        startedAt: 'startedAt',
        apNumber: 'apNumber',
        salutation: 'salutation',
        title: 'title',
        nickName: 'nickName',
        firstName: 'firstName',
        lastName: 'lastName',
        reason: 'reason',
    };

    dataSource: MatTableDataSource<any>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private tunstallEmergencyListService: TunstallEmergencyListService
    ) {
        this.dataSource = new MatTableDataSource([]);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            const token = params.eaf;
            if (token === undefined) {
                this.router.navigate(['404']);
            } else {
                this.token = token;

                this.tunstallEmergencyListService
                    .findLastTunstallEmergencyList(token)
                    .subscribe((received) => {
                        this.isLoading = false;
                        this.dataSource = new MatTableDataSource(received.body);
                        // this.dataSource = new MatTableDataSource(dummyData);
                    });
            }
        });
    }

    redirect(urlAgentAccessToken: string) {
        // Redirect to the new web app
        const envMapping: { [key: string]: string } = {
            'https://repository.test.almaphil.net':
                'https://adele.test.almaphil.de',
            'https://repository.dev.almaphil.net':
                'https://adele.dev.almaphil.de',
            'https://repository.prod.almaphil.net': 'https://adele.almaphil.de',
        };

        const matchedEnv = Object.keys(envMapping).find((env) =>
            urlAgentAccessToken.includes(env)
        );

        if (matchedEnv) {
            let newUrl = urlAgentAccessToken.replace(
                matchedEnv,
                envMapping[matchedEnv]
            );
            if (window.location.origin.includes('http://localhost:4200')) {
                newUrl = urlAgentAccessToken.replace(
                    matchedEnv,
                    'http://localhost:4200'
                );
            }
            window.location.href = newUrl;
        }
    }

    openInNewTab(url?: string): void {
        if (url) {
            window.open(url, '_blank');
        } else {
            console.error('Invalid URL');
        }
    }
}
