<mat-form-field class="w-full p-4">
    <ng-container *transloco="let t">
        <!-- <mat-label>Main account</mat-label> -->
        <mat-select
            class="font-bold"
            name="account"
            [(ngModel)]="selectedAccount"
            (openedChange)="openChange($event)"
        >
            <input
                class="w-full p-2"
                [formControl]="accountInputCtrl"
                [placeholder]="t('SEARCH')"
                (click)="$event.stopPropagation()"
                (keydown)="$event.stopPropagation()"
            />
            <cdk-virtual-scroll-viewport
                itemSize="50"
                minBufferPx="200"
                maxBufferPx="400"
                [style.height.px]="4 * 48"
            >
                @if (baseAccount?.userId !== selectedAccount?.userId) {
                <mat-option
                    class="base-user font-medium"
                    [value]="baseAccount"
                    (onSelectionChange)="onSelectionChange($event)"
                >
                    {{ baseAccount?.firstName }}
                    {{ baseAccount?.lastName }}
                </mat-option>
                }
                <mat-option
                    class="active-user hidden"
                    [value]="selectedAccount"
                >
                    {{ selectedAccount?.firstName }}
                    {{ selectedAccount?.lastName }}
                </mat-option>
                <mat-option
                    *cdkVirtualFor="let account of filterAccounts"
                    [value]="account"
                    (onSelectionChange)="onSelectionChange($event)"
                >
                    {{ account.firstName }}
                    {{ account.lastName }}
                </mat-option>
                @if (!(filterAccounts | async)?.length) {
                <div class="mt-8 p-4 text-center">
                    @if (this.accountInputCtrl?.value?.length) {
                    {{ t("NO-ACCOUNT") }}
                    } @else {
                    {{ t("NO-PERSONS-CARED-FOR-BY-ME") }}
                    }
                </div>
                }
            </cdk-virtual-scroll-viewport>
        </mat-select>
    </ng-container>
</mat-form-field>
