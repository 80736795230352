import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAdditionalUserData } from 'app/modules/shared/models/additional-user-data.model';
import { GenericDTO, IGenericDTO } from 'app/modules/shared/models/generic-dto.model';
import { createRequestOption } from '../request/request-util';
import { environment } from 'environments/environment';

// import { isPresent } from 'app/core/util/operators';
// import { DATE_FORMAT } from 'app/config/input.constants';
// import { ApplicationConfigService } from 'app/core/config/application-config.service';
// import { createRequestOption } from 'app/core/request/request-util';

export type EntityResponseType = HttpResponse<IAdditionalUserData>;
export type EntityArrayResponseType = HttpResponse<IAdditionalUserData[]>;

@Injectable({ providedIn: 'root' })
export class AdditionalUserDataService {
  protected repoURL = environment.repoUrl;
      protected resourceUrl = this.repoURL + '/api/additional-user-data';

  constructor(protected http: HttpClient) {}

//   create(additionalUserData: IAdditionalUserData): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(additionalUserData);
//     return this.http
//       .post<IAdditionalUserData>(this.resourceUrl, copy, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   update(additionalUserData: IAdditionalUserData): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(additionalUserData);
//     return this.http
//       .put<IAdditionalUserData>(`${this.resourceUrl}/${getAdditionalUserDataIdentifier(additionalUserData) as number}`, copy, {
//         observe: 'response',
//       })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   partialUpdate(additionalUserData: IAdditionalUserData): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(additionalUserData);
//     return this.http
//       .patch<IAdditionalUserData>(`${this.resourceUrl}/${getAdditionalUserDataIdentifier(additionalUserData) as number}`, copy, {
//         observe: 'response',
//       })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   find(id: number): Observable<EntityResponseType> {
//     return this.http
//       .get<IAdditionalUserData>(`${this.resourceUrl}/${id}`, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   query(req?: any): Observable<EntityArrayResponseType> {
//     const options = createRequestOption(req);
//     return this.http
//       .get<IAdditionalUserData[]>(this.resourceUrl, { params: options, observe: 'response' })
//       .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
//   }

//   queryByUser(req?: any): Observable<EntityResponseType> {
//     const options = createRequestOption(req);
//     return this.http.get<IAdditionalUserData>(`${this.resourceUrl}/single`, { params: options, observe: 'response' });
//   }

  getUserEmergencyId(id: number): Observable<HttpResponse<string>> {
    return this.http.get<string>(`${this.resourceUrl}/${id}/user-emergency-id`, { observe: 'response' });
  }

  getUserEmergencyIdByEmergencyAgentToken(emergencyAgentToken: string, req?: any): Observable<HttpResponse<GenericDTO>> {
    const options = createRequestOption(req);
    return this.http.get<IGenericDTO>(`${this.resourceUrl}/emergency-agent-token/${emergencyAgentToken}`, {
      params: options,
      observe: 'response',
    });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

//   addAdditionalUserDataToCollectionIfMissing(
//     additionalUserDataCollection: IAdditionalUserData[],
//     ...additionalUserDataToCheck: (IAdditionalUserData | null | undefined)[]
//   ): IAdditionalUserData[] {
//     const additionalUserData: IAdditionalUserData[] = additionalUserDataToCheck.filter(isPresent);
//     if (additionalUserData.length > 0) {
//       const additionalUserDataCollectionIdentifiers = additionalUserDataCollection.map(
//         additionalUserDataItem => getAdditionalUserDataIdentifier(additionalUserDataItem)!
//       );
//       const additionalUserDataToAdd = additionalUserData.filter(additionalUserDataItem => {
//         const additionalUserDataIdentifier = getAdditionalUserDataIdentifier(additionalUserDataItem);
//         if (additionalUserDataIdentifier == null || additionalUserDataCollectionIdentifiers.includes(additionalUserDataIdentifier)) {
//           return false;
//         }
//         additionalUserDataCollectionIdentifiers.push(additionalUserDataIdentifier);
//         return true;
//       });
//       return [...additionalUserDataToAdd, ...additionalUserDataCollection];
//     }
//     return additionalUserDataCollection;
//   }

//   protected convertDateFromClient(additionalUserData: IAdditionalUserData): IAdditionalUserData {
//     return Object.assign({}, additionalUserData, {
//       birthday: additionalUserData.birthday?.isValid() ? additionalUserData.birthday.format(DATE_FORMAT) : undefined,
//     });
//   }

//   protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
//     if (res.body) {
//       res.body.birthday = res.body.birthday ? dayjs(res.body.birthday) : undefined;
//     }
//     return res;
//   }

//   protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
//     if (res.body) {
//       res.body.forEach((additionalUserData: IAdditionalUserData) => {
//         additionalUserData.birthday = additionalUserData.birthday ? dayjs(additionalUserData.birthday) : undefined;
//       });
//     }
//     return res;
//   }
}
